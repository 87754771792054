import { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { UserContext } from "../../../context/userContext";
import Layout from "../../../components/Layout";

import { Mixpanel } from "../../../utils/Mixpanel";

const FormComplete = () => {
  const user = useContext(UserContext);
  const history = useHistory();

  const logout = async () => {
    user.setId();
    user.setUserDetails({});
    user.setFirstName("");
    user.setEmail("");
    user.setLocation("");
    user.setPreassessment({});
    user.setBookingDetails({});
  };

  useEffect(() => {
    if (!user.userDetails.id) {
      //send to login with param attached
      history.push({
        pathname: "/",
      });
    }
    //TODO: User needs to be logged out when they visit this page

    setTimeout(async () => {
      await logout();
      history.replace("/");
    }, 10000);

    Mixpanel.track("Client Registration Form | Complete");
    window.scrollTo({ top: 0, behavior: "smooth" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout>
      <div className="mt-8 text-center">
        <h1 className="text-5xl text-offwhite font-semibold mb-8">
          Thanks {user.userDetails.first_name}
        </h1>
        <h2 className="text-3xl text-offwhite font-semibold mb-8">
          We're looking forward to seeing you at the Clinic.
        </h2>
        <p className="text-lg text-offwhite font-semibold mb-8">
          This page will close in 10 seconds...
        </p>
      </div>
    </Layout>
  );
};

export default FormComplete;
