import { useContext, useEffect, useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { useHistory, useLocation, Link } from "react-router-dom";

import { UserContext } from "../../context/userContext";
import Layout from "../../components/Layout";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";

import { Mixpanel } from "../../utils/Mixpanel";

const Login = () => {
  const history = useHistory();
  const location = useLocation();
  const user = useContext(UserContext);

  const { register, handleSubmit } = useForm();

  const [loggingIn, setIsLoggingIng] = useState(false);
  const [error, setError] = useState(false);
  const [prev, setPrev] = useState(undefined);

  const loginUser = async (data) => {
    setIsLoggingIng(true);

    try {
      const { email, password } = data;
      const res = await axios({
        method: "POST",
        url: process.env.REACT_APP_API_URL + "/client/login",
        data: { client: { email: email, password: password } },
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (res.status !== 200) {
        throw new Error("Invalid details");
      }

      await user.setUserDetails(res.data);
      setIsLoggingIng(false);
    } catch (error) {
      console.log(error);
      setIsLoggingIng(false);
      setError(true);
      Mixpanel.track("Login | Failure");
    }
  };

  //checks if they need to be sent somewhere specific after completing login
  const checkIfPrev = () => {
    if (!location.search) {
      return;
    }
    const params = new URLSearchParams(location.search);
    if (!params.get("prev")) {
      return;
    }

    setPrev(params.get("prev"));
  };

  useEffect(() => {
    if (user.userDetails.id) {
      Mixpanel.track("Login | Success");

      if (prev) {
        history.push(prev);
      } else {
        history.push("/onboarding");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.userDetails]);

  useEffect(() => {
    checkIfPrev();
    Mixpanel.track("View | Login");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout>
      <div className="preAssessmentm-auto py-8 px-0 md:px-4 w-full md:w-3/4 lg:w-3/4 xl:w-2/3 h-auto my-8">
        <form
          onSubmit={handleSubmit(loginUser)}
          className="mb-4 bg-offwhite p-8 rounded"
        >
          <div className="mb-8 text-center">
            <p className="text-3xl font-bold">Login</p>
          </div>
          {error ? (
            <div
              className="flex items-center bg-joy text-white text-sm font-bold px-4 py-3 w-full mb-8 mt-8 rounded"
              role="alert"
            >
              <p className="text-left">
                Nothing found for that email address and password combination.{" "}
                <br />
                <br />
                Please{" "}
                <Link to="/register" className="underline">
                  register
                </Link>{" "}
                or try again. Maybe you{" "}
                <Link to="/forgot" className="underline">
                  forgot your password
                </Link>
                ?
                <br />
                <br />
                If you're having issues please call us on 1300 859 785
              </p>
            </div>
          ) : null}
          <div className="mb-4">
            <label className="form-label">Email</label>
            <input
              name="email"
              ref={register({ required: true })}
              className="text-box"
              placeholder="enter your email address"
              autoCapitalize="none"
              autoFocus="autofocus"
            ></input>
            {/* <pre>{JSON.stringify(user.userDetails, null, 2)}</pre> */}
          </div>
          <div className="mb-4">
            <label className="form-label">Password</label>
            <input
              name="password"
              ref={register({ required: true })}
              className="text-box"
              placeholder="enter your password"
              type="password"
              autoCapitalize="none"
            ></input>
          </div>
          <div className="mb-4">
            <button type="submit" className="btn-joy">
              {loggingIn ? (
                <FontAwesomeIcon icon={faCircleNotch} spin />
              ) : (
                "Log In"
              )}
            </button>
          </div>
          <div className="">
            Don't have a password or forgot?{" "}
            <Link to="/forgot" className="underline">
              Click here
            </Link>
          </div>
        </form>
      </div>
    </Layout>
  );
};

export default Login;
