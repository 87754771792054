import { useContext, useEffect, useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { useHistory, useLocation } from "react-router-dom";

import { UserContext } from "../../../context/userContext";
import Layout from "../../../components/Layout";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { Mixpanel } from "../../../utils/Mixpanel";
import * as Sentry from "@sentry/react";

import ProgressBar from "../../../components/ProgressBar";

const PersonalDetails = () => {
  const history = useHistory();
  const location = useLocation();
  const user = useContext(UserContext);

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const [updating, setUpdating] = useState(false);
  const [error, setError] = useState();

  const updateUser = async (data) => {
    if (!user.email) {
      setError("No email found - exiting");
    }

    setUpdating(true);
    setError();

    const response = {
      occupation: data.occupation,
      ethnicity: data.ethnicity,
      source: data.source,
      emergency_contact_name: data.emergency_contact_name,
      emergency_relationship: data.emergency_relationship,
      emergency_phone: data.emergency_phone,
      gp: data.gp,
      clinic_name: data.clinic_name,
      clinic_address: data.clinic_address,
      gp_contact_number: data.gp_contact_number,
    };
    //merge existing profile with new answers
    const profile = Object.assign({}, user.userDetails, response);

    try {
      const payload = {
        email: user.userDetails.email,
        profile: profile,
      };

      const res = await axios({
        method: "POST",
        url: process.env.REACT_APP_API_URL + "/client/update",
        data: payload,
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (res.data.status && res.data.status === 404) {
        throw new Error(res.data.reply);
      }
      setUpdating(false);
      Mixpanel.track("Client Registration Form | Personal Details | Success");

      history.push("/client-registration/preassessment/1");
    } catch (error) {
      setUpdating(false);

      await setError(error.message);

      document.getElementById("form").scrollIntoView({
        behavior: "smooth",
      });

      Sentry.captureException(error);
      setUpdating(false);
      Mixpanel.track("Client Registration Form | Personal Details | Failure");
    }
  };

  useEffect(() => {
    if (!user.userDetails.id) {
      //send to login with param attached
      history.push({
        pathname: "/login",
        search: `?prev=${location.pathname}`,
      });
    }
    Mixpanel.track("Client Registration Form | Personal Details | View");
    window.scrollTo({ top: 0, behavior: "smooth" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout>
      <div className="preAssessmentm-auto py-8 px-0 md:px-4 w-full md:w-3/4 lg:w-3/4 xl:w-2/3 h-auto my-8">
        <form
          onSubmit={handleSubmit(updateUser)}
          className="mb-4 bg-offwhite p-4 md:p-8 rounded"
          id="form"
        >
          {error ? (
            <div
              className="flex items-center bg-joy text-offwhite text-sm font-bold px-4 py-3 w-full mb-8 mt-8 rounded"
              role="alert"
              id="error"
            >
              <svg
                className="fill-current w-4 h-4 mr-2"
                xmlns="https://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M12.432 0c1.34 0 2.01.912 2.01 1.957 0 1.305-1.164 2.512-2.679 2.512-1.269 0-2.009-.75-1.974-1.99C9.789 1.436 10.67 0 12.432 0zM8.309 20c-1.058 0-1.833-.652-1.093-3.524l1.214-5.092c.211-.814.246-1.141 0-1.141-.317 0-1.689.562-2.502 1.117l-.528-.88c2.572-2.186 5.531-3.467 6.801-3.467 1.057 0 1.233 1.273.705 3.23l-1.391 5.352c-.246.945-.141 1.271.106 1.271.317 0 1.357-.392 2.379-1.207l.6.814C12.098 19.02 9.365 20 8.309 20z" />
              </svg>
              <div className="flex justify-center flex-col w-full">
                <p className="mb-2">{error}</p>
              </div>
            </div>
          ) : null}

          <p className="text-3xl font-bold mb-4">About You</p>

          <div className="grid grid-cols-1 md:grid-cols-2 md:gap-2">
            <div className="mb-4">
              <label className="form-label">What is your occupation?</label>
              <input
                name="occupation"
                ref={register({ required: false })}
                className="text-box"
                placeholder="Occupation"
                autoCapitalize="none"
              ></input>
            </div>
            <div className="mb-4">
              <label className="form-label">What is your ethnicity?</label>
              <input
                name="ethnicity"
                ref={register({ required: false })}
                className="text-box"
                placeholder="Ethnicity"
                autoCapitalize="none"
              ></input>
            </div>
          </div>
          <div className="mb-4">
            <label className="form-label">
              How did you find out about Endeavour Wellness Clinic?*
            </label>
            <select
              name="source"
              ref={register({ required: true, minLength: 1 })}
              className="text-box"
            >
              <option value="Client Referral" defaultValue>
                Client Referral
              </option>
              <option value="Internet Search">Internet Search</option>
              <option value="Social Media">Social Media</option>
            </select>
            {errors.source ? (
              <span className="form-field-error">
                {errors.source && "Source is required"}
              </span>
            ) : null}
          </div>

          <p className="text-3xl font-bold mt-12 mb-4 text-left">
            Emergeny Contact Information
          </p>
          <div className="mb-4">
            <label className="form-label">Emergency contact's name*</label>
            <input
              name="emergency_contact_name"
              ref={register({ required: true })}
              className="text-box"
              placeholder="Name"
              autoCapitalize="none"
            ></input>
            {errors.emergency_contact_name ? (
              <span className="form-field-error">
                {errors.emergency_contact_name && "Required field"}
              </span>
            ) : null}
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 md:gap-2">
            <div className="mb-4">
              <label className="form-label">Relationship with contact*</label>
              <input
                name="emergency_relationship"
                ref={register({ required: true })}
                className="text-box"
                placeholder="Relationship"
                autoCapitalize="none"
              ></input>
              {errors.emergency_relationship ? (
                <span className="form-field-error">
                  {errors.emergency_relationship && "Required field"}
                </span>
              ) : null}
            </div>
            <div className="mb-4">
              <label className="form-label">Contact's phone number*</label>
              <input
                name="emergency_phone"
                ref={register({ required: true })}
                className="text-box"
                type="telephone"
                placeholder="0123456789"
              ></input>
              {errors.emergency_phone ? (
                <span className="form-field-error">
                  {errors.emergency_phone && "Required field"}
                </span>
              ) : null}
            </div>
          </div>

          <p className="text-3xl font-bold mt-8 mb-4 text-left">
            Healthcare Information
          </p>
          <div className="mb-4">
            <label className="form-label">Doctor's name*</label>
            <input
              name="gp"
              ref={register({ required: true })}
              className="text-box"
              placeholder="Doctor's name"
              autoCapitalize="none"
            ></input>
            {errors.gp ? (
              <span className="form-field-error">
                {errors.gp && "Required field"}
              </span>
            ) : null}
          </div>
          <div className="mb-4">
            <label className="form-label">Clinic name*</label>
            <input
              name="clinic_name"
              ref={register({ required: true })}
              className="text-box"
              placeholder="Clinic name"
              autoCapitalize="none"
            ></input>
            {errors.clinic_name ? (
              <span className="form-field-error">
                {errors.clinic_name && "Required field"}
              </span>
            ) : null}
          </div>
          <div className="mb-4">
            <label className="form-label">Clinic address*</label>
            <input
              name="clinic_address"
              ref={register({ required: true })}
              className="text-box"
              placeholder="Clinic address"
              autoCapitalize="none"
            ></input>
            {errors.clinic_address ? (
              <span className="form-field-error">
                {errors.clinic_address && "Required field"}
              </span>
            ) : null}
          </div>
          <div className="mb-4">
            <div className="mb-4">
              <label className="form-label">Clinic's phone number*</label>
              <input
                name="gp_contact_number"
                ref={register({ required: true })}
                className="text-box"
                type="telephone"
                placeholder="0123456789"
              ></input>
            </div>
            {errors.gp_contact_number ? (
              <span className="form-field-error">
                {errors.gp_contact_number && "Required field"}
              </span>
            ) : null}
          </div>

          <div className="mb-4 mt-8">
            <button type="submit" className="btn-joy">
              {updating ? (
                <FontAwesomeIcon icon={faCircleNotch} spin />
              ) : (
                "Continue"
              )}
            </button>
          </div>
          <div className="flex justify-center w-full">
            <ProgressBar currentStep={1} maxSteps={4} />
          </div>
        </form>
      </div>
    </Layout>
  );
};

export default PersonalDetails;
