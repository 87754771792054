import { memo } from "react";

const ProgressBar = ({ currentStep, maxSteps }) => {
  return (
    <div className="flex flex-col">
      <label for="progress" className="mb-4 text-center">
        Page {currentStep} / {maxSteps}
      </label>
      <progress
        id="progress"
        max="100"
        value={(currentStep / maxSteps) * 100}
        className="rounded"
      ></progress>
    </div>
  );
};

export default memo(ProgressBar);
