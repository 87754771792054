import { useContext, useEffect, useState } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { useHistory, useLocation } from "react-router-dom";
import _ from "lodash";

import { UserContext } from "../../../context/userContext";
import Layout from "../../../components/Layout";
import { preAssessmentTwoSchema } from "../../../models/preassessment_two_schema";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { Mixpanel } from "../../../utils/Mixpanel";
import * as Sentry from "@sentry/react";

import ProgressBar from "../../../components/ProgressBar";

const PreAssessmentTwo = () => {
  const formattedPreAssesmentTwoSchema = Object.entries(
    _.groupBy(preAssessmentTwoSchema, "condition_category")
  );

  const history = useHistory();
  const location = useLocation();

  const user = useContext(UserContext);
  const [updating, setUpdating] = useState(false);
  const [error, setError] = useState();

  //Hook Form setup
  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: {
      current_medications: [
        {
          medication_name: "Medication Name",
          dosage: "Dosage",
          duration: "Duration taken",
          condition: "Condition applies to",
        },
      ],
    },
  });

  const {
    fields: currentMedicationFields,
    append: currentMedicationAppend,
    remove: currentMedicationRemove,
  } = useFieldArray({
    control,
    name: "current_medications",
  });

  //End Hook Form Setup

  const updateUser = async (data) => {
    if (!user.userDetails.email) {
      setError("No email found - exiting");
    }

    try {
      setUpdating(true);
      setError();

      const preassessment = Object.assign({}, data, user.preassessment);
      await user.setPreassessment(preassessment);
      history.push("/client-registration/preassessment/3");
    } catch (error) {
      setUpdating(false);

      await setError(error.message);

      document.getElementById("form").scrollIntoView({
        behavior: "smooth",
      });

      Sentry.captureException(error);

      Mixpanel.track("Client Registration Form | Preassesment Two | Failure");
    } finally {
      setTimeout(() => {
        setUpdating(false);
      }, 1000);
    }
  };

  useEffect(() => {
    Mixpanel.track("Client Registration Form | Preassesment Two | View");
    if (!user.userDetails.id) {
      //send to login with param attached
      history.push({
        pathname: "/login",
        search: `?prev=${location.pathname}`,
      });
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout>
      <div className="preAssessmentm-auto py-8 px-0 md:px-4 w-full md:w-3/4 lg:w-3/4 xl:w-2/3 h-auto my-8">
        {!user.userDetails.email ? (
          <div className="mb-4 bg-offwhite p-4 md:p-8 rounded">
            <p className="text-center font-semibold">Something went wrong</p>
          </div>
        ) : (
          <form
            onSubmit={handleSubmit(updateUser)}
            className="mb-4 bg-offwhite p-4 md:p-8 rounded"
            id="form"
          >
            {error ? (
              <div
                className="flex items-center bg-joy text-offwhite text-sm font-bold px-4 py-3 w-full mb-8 mt-8 rounded"
                role="alert"
                id="error"
              >
                <svg
                  className="fill-current w-4 h-4 mr-2"
                  xmlns="https://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M12.432 0c1.34 0 2.01.912 2.01 1.957 0 1.305-1.164 2.512-2.679 2.512-1.269 0-2.009-.75-1.974-1.99C9.789 1.436 10.67 0 12.432 0zM8.309 20c-1.058 0-1.833-.652-1.093-3.524l1.214-5.092c.211-.814.246-1.141 0-1.141-.317 0-1.689.562-2.502 1.117l-.528-.88c2.572-2.186 5.531-3.467 6.801-3.467 1.057 0 1.233 1.273.705 3.23l-1.391 5.352c-.246.945-.141 1.271.106 1.271.317 0 1.357-.392 2.379-1.207l.6.814C12.098 19.02 9.365 20 8.309 20z" />
                </svg>
                <div className="flex justify-center flex-col w-full">
                  <p className="mb-2">{error}</p>
                </div>
              </div>
            ) : null}

            <section className="mb-8">
              <p className="text-3xl font-bold mb-4">
                Past & Current Conditions for {user.userDetails.first_name}
              </p>
              <p className="text-xl">
                Please select all current or past conditions that apply to you
              </p>
            </section>

            {formattedPreAssesmentTwoSchema.map((category, index) => (
              <div className="mb-4 pb-4 border-b border-grey" key={index}>
                <section>
                  <div className="grid grid-cols-3 font-semibold">
                    <span>{category[0]}</span>
                    <label className="flex justify-center">Current</label>
                    <label className="flex justify-center">Past</label>
                  </div>
                  {category[1].map((condition, index) => (
                    <>
                      <div className="mb-4"></div>
                      <div className="grid grid-cols-3 items-center justify-center">
                        <section>
                          <label className="pr-4">
                            {condition.condition_label}
                          </label>
                        </section>
                        <section className="flex justify-center">
                          <input
                            key={`${condition.condition_id}_current`}
                            type="checkbox"
                            name={`${condition.condition_id}_current`}
                            value="1"
                            ref={register()}
                            className="form-checkbox flex justify-center"
                          />
                        </section>
                        <section className="flex justify-center">
                          <input
                            key={`${condition.condition_id}_past`}
                            type="checkbox"
                            name={`${condition.condition_id}_past`}
                            value="1"
                            ref={register()}
                            className="form-checkbox"
                          />
                        </section>
                      </div>
                    </>
                  ))}
                </section>
              </div>
            ))}

            <div className="pt-4 mb-4">
              <label className="form-label">
                Please list any current medications / supplements / herbal
                remedies you are taking.
              </label>

              <ul>
                {currentMedicationFields.map((item, index) => {
                  return (
                    <li
                      key={item.id}
                      className="mt-4 mb-8 flex flex-row gap-2 w-full"
                    >
                      <div className="grid grid-cols-1 xl:grid-cols-2 gap-2 w-full">
                        <input
                          className="text-area"
                          // defaultValue={`${item.medication_name}`}
                          placeholder={`${item.medication_name}`}
                          name={`current_medications[${index}].medication_name`}
                          ref={register({ required: false })}
                          key={`${item.id}_medication_name`}
                          onFocus={(e) => (e.target.value = "")}
                        ></input>
                        <input
                          className="text-area"
                          placeholder={`${item.dosage}`}
                          name={`current_medications[${index}].dosage`}
                          ref={register({ required: false })}
                          key={`${item.id}_dosage`}
                          onFocus={(e) => (e.target.value = "")}
                        ></input>
                        <input
                          className="text-area"
                          placeholder={`${item.duration}`}
                          name={`current_medications[${index}].duration`}
                          ref={register({ required: false })}
                          key={`${item.id}_duration`}
                          onFocus={(e) => (e.target.value = "")}
                        ></input>
                        <input
                          className="text-area"
                          placeholder={`${item.condition}`}
                          name={`current_medications[${index}].condition`}
                          ref={register({ required: false })}
                          key={`${item.id}_condition`}
                          onFocus={(e) => (e.target.value = "")}
                        ></input>
                      </div>
                      <button
                        type="button"
                        onClick={() => {
                          currentMedicationRemove(index);
                        }}
                      >
                        X
                      </button>
                    </li>
                  );
                })}
              </ul>
              <section className="my-4">
                <button
                  className="font-normal"
                  type="button"
                  onClick={() => {
                    currentMedicationAppend({
                      medication_name: "Medication Name",
                      dosage: "Dosage",
                      duration: "Duration taken",
                      condition: "Condition applies to",
                    });
                  }}
                >
                  + Add More
                </button>
              </section>
            </div>

            <div className="mb-4 mt-8">
              <button type="submit" className="btn-joy">
                {updating ? (
                  <FontAwesomeIcon icon={faCircleNotch} spin />
                ) : (
                  "Continue"
                )}
              </button>
            </div>
            <div className="flex justify-center w-full">
              <ProgressBar currentStep={3} maxSteps={4} />
            </div>
          </form>
        )}
      </div>
    </Layout>
  );
};

export default PreAssessmentTwo;
