import React, { useEffect, useState } from "react";
// import axios from "axios";
import { useForm } from "react-hook-form";
import { Link, useLocation } from "react-router-dom";

import Layout from "../../components/Layout";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";

import { Mixpanel } from "../../utils/Mixpanel";

import axios from "axios";

const ResetPassword = () => {
  const { register, handleSubmit } = useForm();

  const [resetting, setResetting] = useState(false);
  const [resetComplete, setResetComplete] = useState(false);
  const [error, setError] = useState(false);
  const [resetToken, setResetToken] = useState();
  const [resetTokenError, setResetTokenError] = useState(false);

  const location = useLocation();

  const getResetToken = () => {
    if (!location.search) {
      setResetTokenError(true);
      return;
    }
    const params = new URLSearchParams(location.search);
    if (!params.get("reset_password_token")) {
      setResetTokenError(true);
      return;
    }
    setResetToken(params.get("reset_password_token"));
  };

  useEffect(() => {
    if (resetTokenError) {
      Mixpanel.track("View | Password Reset | No Token");
      setError(true);
    }
  }, [resetTokenError]);

  useEffect(() => {
    Mixpanel.track("View | Password Reset");
    getResetToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changePassword = async (data) => {
    await setResetting(true);

    if (data.password !== data.password2) {
      setResetting(false);
      alert("Those passwords don't match");
      return;
    }

    const formData = new FormData();

    formData.append("utf8", "%E2%9C%93");
    formData.append("_method", "put");
    formData.append("authenticity_token", Math.random().toString(36));
    formData.append("client[reset_password_token]", resetToken);
    formData.append("client[password]", data.password);
    formData.append("client[passwordConfirmation]", data.password);
    formData.append("commit", "Change+my+password");

    try {
      const res = await axios({
        method: "POST",
        url: process.env.REACT_APP_API_URL + "/clients/password",
        data: formData,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });

      if (res.status !== 200) {
        throw new Error("Failure");
      }

      setResetting(false);
      setResetComplete(true);
      Mixpanel.track("Forgot Password | Reset");
    } catch (error) {
      console.log(error);
      setResetting(false);
      setError(true);
      Mixpanel.track("Forgot Password | Failure");
    }
  };

  return (
    <Layout>
      <div className="preAssessmentm-auto py-8 px-0 md:px-4 w-full md:w-3/4 lg:w-3/4 xl:w-2/3 h-auto my-8">
        <form
          onSubmit={handleSubmit(changePassword)}
          className="mb-4 bg-offwhite p-8 rounded"
        >
          <div className="mb-8 text-center">
            <p className="text-3xl font-bold">Set a new password</p>
          </div>
          {error ? (
            <div
              className="flex items-center bg-joy text-white text-sm font-bold px-4 py-3 w-full mb-8 mt-8 rounded"
              role="alert"
            >
              <p className="text-left">
                That didnt work - click{" "}
                <Link to="/forgot">
                  <u>here</u>
                </Link>{" "}
                to try again
              </p>
            </div>
          ) : resetComplete ? (
            <div
              className="flex items-center text-black text-base px-4 py-3 w-full mb-8 mt-8 rounded"
              role="alert"
            >
              <p className="text-left">
                Password Reset successfully! You can login{" "}
                <Link to="/login">
                  <u>here</u>
                </Link>
              </p>
            </div>
          ) : (
            <>
              <div className="mb-4">
                <label className="form-label">
                  Please enter a new password (8+ characters)
                </label>
                <input
                  name="password"
                  ref={register({ required: true, minLength: 8 })}
                  className="text-box"
                  placeholder="8+ characters"
                  autoCapitalize="none"
                  autoFocus="autofocus"
                  type="password"
                ></input>
              </div>
              <div className="mb-4">
                <label className="form-label">Re-enter the password</label>
                <input
                  name="password2"
                  ref={register({ required: true, minLength: 8 })}
                  className="text-box"
                  placeholder="8+ characters"
                  autoCapitalize="none"
                  type="password"
                ></input>
              </div>

              <div className="mb-4">
                <button type="submit" className="btn-joy">
                  {resetting ? (
                    <FontAwesomeIcon icon={faCircleNotch} spin />
                  ) : (
                    "Change Password"
                  )}
                </button>
              </div>
            </>
          )}
        </form>
      </div>
    </Layout>
  );
};

export default ResetPassword;
