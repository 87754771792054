import { useContext, useEffect } from "react";
import { Link } from "react-router-dom";

import { UserContext } from "../../context/userContext";
import Layout from "../../components/Layout";

import { Mixpanel } from "../../utils/Mixpanel";

const BookingComplete = () => {
  const user = useContext(UserContext);

  useEffect(() => {
    try {
      if (!user || !user.bookingDetails) {
        //   window.alert("Something went wrong");
        //   history.replace("/");
        throw new Error("Missing booking details");
      }
      Mixpanel.track("View | Booking Complete");
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout>
      <div className="mb-8 text-center ">
        <h1 className="text-5xl text-offwhite font-semibold mb-8">
          Booking Complete
        </h1>
        <div className="text-left">
          {user.bookingDetails &&
          user.bookingDetails.location &&
          user.bookingDetails.date ? (
            <>
              <p className="text-xl text-offwhite font-semibold mb-2">
                We're looking forward to seeing you at our{" "}
                {user.bookingDetails.location.name} clinic on&nbsp;
                {user.bookingDetails.date.day}/{user.bookingDetails.date.month}/
                {user.bookingDetails.date.year}.
              </p>
              <p className="text-xl text-offwhite font-semibold mb-2">
                Your booking reference is {user.bookingDetails.bookingId}
              </p>
              <p className="text-xl text-offwhite font-semibold mb-8">
                You can view details of the clinic&nbsp;location&nbsp;
                <a
                  href={`https://www.endeavourclinic.com.au/locations/${user.bookingDetails.location.name
                    .replace(" ", "-")
                    .toLowerCase()}`}
                  className="underline"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  here.
                </a>
              </p>
            </>
          ) : null}
          <p className="text-xl text-offwhite font-semibold mb-8 flex flex-col">
            To ensure we give you the best treatment possible, please complete
            our client&nbsp;registration&nbsp;form.
          </p>
          <div className="text-center">
            <Link to="/client-registration/personal-details">
              <button
                className="btn-joy md:text-lg text-xl inline-block py-3 px-5 leading-none rounded border font-semibold"
                id="new-client-registration-form-link"
              >
                New Client Registration Form
              </button>
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default BookingComplete;
