import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import queryString from "query-string";

import Layout from "../../components/Layout";
import ClipLoader from "react-spinners/ClipLoader";
import { useWindowSize } from "react-use";
import Confetti from "react-confetti";
import * as Sentry from "@sentry/react";
import { Mixpanel } from "../../utils/Mixpanel";

const PaymentComplete = () => {
  const { width, height } = useWindowSize();
  const { search } = useLocation();
  const [loading, setLoading] = useState(true);
  const [accessCode, setAccessCode] = useState(0);
  const [bookingId, setBookingId] = useState(0);
  const [complete, setComplete] = useState(false);

  useEffect(() => {
    const getTransactionDetails = async () => {
      Mixpanel.track("Payment | Get Transaction Details");
      const values = queryString.parse(search);

      if (!values.booking_id || !values.AccessCode) {
        setLoading(false);
        return;
      }

      await setBookingId(values.booking_id);

      setAccessCode(values.AccessCode);
    };

    getTransactionDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const validateTransaction = async () => {
      try {
        if (complete) {
          setLoading(false);
          return;
        }
        Mixpanel.track("Payment | Validate Transaction Details");
        const res = await axios({
          method: "POST",
          url: `${process.env.REACT_APP_API_URL}/booking/check_payment?booking_id=${bookingId}&access_code=${accessCode}`,
        });
        if (res.status === 401) {
          throw new Error("Payment Failed");
        }
        if (res.data.paid === 1 && res.data.status === "Booked") {
          await setComplete(true);
          Mixpanel.track("Payment | Transaction Complete");
          setLoading(false);
        } else {
          setLoading(false);
        }
        Mixpanel.track("Payment | Got Transaction Details");
      } catch (error) {
        Sentry.captureException(error);
        console.log(error);
        setLoading(false);
      }
    };

    if (!bookingId || !accessCode) {
      return;
    }

    //We wait 3 seconds before validating transaction to give payment processor time to process

    setTimeout(() => {
      validateTransaction();
    }, 3000);
  }, [bookingId, accessCode]);

  return (
    <Layout>
      <div className="o py-8 px-0 md:px-4 w-full md:w-3/4 lg:w-3/4 xl:w-1/2 h-auto my-8">
        <div className="mb-4 bg-offwhite p-4 md:p-8 rounded">
          <div className="flex items-center justify-center flex-col">
            {loading ? (
              <>
                <h1 className="text-2xl font-semibold mb-4">
                  Processing Payment
                </h1>
                <ClipLoader size={48} color={"black"} loading={true} />
              </>
            ) : complete ? (
              <>
                <h1 className="text-2xl font-semibold mb-4">
                  Payment successful!
                </h1>
                <h2 className="text-xl mb-8">
                  Thank you. We're looking forward to seeing you at the
                  Endeavour Clinic.
                </h2>
                <Confetti width={width} height={height} />
              </>
            ) : (
              <>
                {" "}
                <h1 className="text-2xl font-semibold mb-4">
                  There was an issue with your payment
                </h1>
                <div className="flex justify-start flex-col">
                  <p className="text-xl mb-4">
                    You will receive an email from eWay with details of the
                    issue.
                  </p>
                  <p className="text-xl mb-8">
                    Click{" "}
                    <a
                      href={`/#/payment?booking_id=${bookingId}`}
                      className="underline"
                    >
                      here
                    </a>{" "}
                    to try again or call and speak to a Clinic Manager on 1300
                    859 785{" "}
                    {bookingId ? `quoting your booking ID of ${bookingId}` : ""}{" "}
                    and we will be happy to help.
                  </p>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default PaymentComplete;
