import { useEffect } from "react";
import { Link } from "react-router-dom";

import Layout from "../../components/Layout";

import { Mixpanel } from "../../utils/Mixpanel";

const Landing = () => {
  useEffect(() => {
    Mixpanel.track("View | Landing");
  }, []);

  return (
    <Layout>
      <div className="mb-8 text-center">
        <h1 className="text-5xl text-offwhite font-semibold mb-2">Welcome</h1>
        <p className="text-2xl text-offwhite font-semibold">
          Which of these best describes&nbsp;you?
        </p>
      </div>
      <div className="grid grid-cols-3 gap-2 md:gap-4">
        <Link to="/register">
          <button
            className="btn-joy h-full text-xs md:text-base w-full"
            id="landing-new-client"
          >
            {" "}
            New Client
          </button>
        </Link>
        <Link to="/login">
          <button
            className="btn-trans-white h-full text-xs md:text-base w-full"
            id="landing-existing-client"
          >
            {" "}
            Existing Client with password
          </button>
        </Link>
        <Link to="/forgot">
          <button
            className="btn-trans-white h-full text-xs md:text-base w-full"
            id="landing-existing-client"
          >
            {" "}
            Existing Client without password
          </button>
        </Link>
      </div>
    </Layout>
  );
};

export default Landing;
