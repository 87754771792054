import { useContext, useEffect, useState } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { useHistory, useLocation } from "react-router-dom";

import { UserContext } from "../../../context/userContext";
import Layout from "../../../components/Layout";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { Mixpanel } from "../../../utils/Mixpanel";
import * as Sentry from "@sentry/react";

import ProgressBar from "../../../components/ProgressBar";

const PreAssessmentOne = () => {
  const history = useHistory();
  const location = useLocation();

  const user = useContext(UserContext);
  const [updating, setUpdating] = useState(false);
  const [error, setError] = useState();

  const {
    register,
    control,
    watch,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: {
      ages: [{ age: "10" }],
      allergy_list: [{ allergy: "Allergy", reaction: "Reaction" }],
      presenting_condition: [{ condition: "" }],
      surgeries_list: [{ detail: "Surgery Details", date: "01/01/2019" }],
    },
  });

  const {
    fields: agesFields,
    append: agesAppend,
    remove: agesRemove,
  } = useFieldArray({
    control,
    name: "ages",
  });

  const {
    fields: allergiesFields,
    append: allergiesAppend,
    remove: allergiesRemove,
  } = useFieldArray({
    control,
    name: "allergy_list",
  });

  const {
    fields: conditionsFields,
    append: conditionsAppend,
    remove: conditionsRemove,
  } = useFieldArray({
    control,
    name: "presenting_condition",
  });

  const {
    fields: surgeryFields,
    append: surgeryAppend,
    remove: surgeryRemove,
  } = useFieldArray({
    control,
    name: "surgeries_list",
  });

  const watchChildren = watch("children");
  const watchConceive = watch("conceive");
  const watchAllergies = watch("allergies");
  const watchSmoker = watch("smoker");
  const watchSurgery = watch("fractures_or_surgeries");

  //End Hook Form Setup

  const updateUser = async (data) => {
    if (!user.userDetails.email) {
      setError("No email found - exiting");
    }

    try {
      setUpdating(true);
      setError();

      await user.setPreassessment(data);
      history.push("/client-registration/preassessment/2");
    } catch (error) {
      setUpdating(false);

      await setError(error.message);

      document.getElementById("form").scrollIntoView({
        behavior: "smooth",
      });

      Sentry.captureException(error);

      Mixpanel.track("Client Registration Form | Preassesment One | Failure");
    } finally {
      setTimeout(() => {
        setUpdating(false);
      }, 1000);
    }
  };

  useEffect(() => {
    Mixpanel.track("Client Registration Form | Preassesment One | View");
    if (!user.userDetails.id) {
      //send to login with param attached
      history.push({
        pathname: "/login",
        search: `?prev=${location.pathname}`,
      });
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout>
      <div className="preAssessmentm-auto py-8 px-0 md:px-4 w-full md:w-3/4 lg:w-3/4 xl:w-2/3 h-auto my-8">
        {!user.userDetails.email ? (
          <div className="mb-4 bg-offwhite p-4 md:p-8 rounded">
            <p className="text-center font-semibold">Something went wrong</p>
          </div>
        ) : (
          <form
            onSubmit={handleSubmit(updateUser)}
            className="mb-4 bg-offwhite p-4 md:p-8 rounded"
            id="form"
          >
            {error ? (
              <div
                className="flex items-center bg-joy text-offwhite text-sm font-bold px-4 py-3 w-full mb-8 mt-8 rounded"
                role="alert"
                id="error"
              >
                <svg
                  className="fill-current w-4 h-4 mr-2"
                  xmlns="https://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M12.432 0c1.34 0 2.01.912 2.01 1.957 0 1.305-1.164 2.512-2.679 2.512-1.269 0-2.009-.75-1.974-1.99C9.789 1.436 10.67 0 12.432 0zM8.309 20c-1.058 0-1.833-.652-1.093-3.524l1.214-5.092c.211-.814.246-1.141 0-1.141-.317 0-1.689.562-2.502 1.117l-.528-.88c2.572-2.186 5.531-3.467 6.801-3.467 1.057 0 1.233 1.273.705 3.23l-1.391 5.352c-.246.945-.141 1.271.106 1.271.317 0 1.357-.392 2.379-1.207l.6.814C12.098 19.02 9.365 20 8.309 20z" />
                </svg>
                <div className="flex justify-center flex-col w-full">
                  <p className="mb-2">{error}</p>
                </div>
              </div>
            ) : null}

            <p className="text-3xl font-bold mb-4">
              Preassesment for {user.userDetails.first_name}
            </p>

            <div className="mb-4">
              <label className="form-label">
                What is your main presenting condition/concern?*
              </label>
              <label className="form-label-light">Please enter up to 5</label>
              <ul>
                {conditionsFields.map((item, index) => {
                  return (
                    <li
                      key={item.id}
                      className="my-4 flex flex-row gap-2 w-full"
                    >
                      <div className="w-4/5">
                        <input
                          className="text-area"
                          placeholder="Enter details of your condition"
                          name={`presenting_condition[${index}].condition`}
                          ref={register({ required: true })}
                          key={item.id}
                          onFocus={(e) => (e.target.value = "")}
                        ></input>
                      </div>

                      {index > 0 ? (
                        <button
                          type="button"
                          onClick={() => {
                            conditionsRemove(index);
                          }}
                        >
                          X
                        </button>
                      ) : null}
                    </li>
                  );
                })}
              </ul>
              <section className="my-4 mb-8">
                <button
                  className="hover:shadow"
                  type="button"
                  onClick={() => {
                    conditionsAppend({
                      condition: "Enter details of your condition",
                    });
                  }}
                >
                  + Add Condition
                </button>
              </section>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-2"></div>

            <div className="mb-4">
              <label className="form-label">Do you have children?</label>
              <select
                className="text-box"
                name="children"
                ref={register({ required: true })}
              >
                <option value="1">Yes</option>
                <option value="0" selected={true}>
                  No
                </option>
              </select>
            </div>

            {watchChildren && watchChildren === "1" ? (
              <div className="mb-8 pl-4">
                <label className="form-label">Please list their ages *</label>
                <ul>
                  {agesFields.map((item, index) => {
                    return (
                      <li
                        key={item.id}
                        className="my-4 flex flex-row gap-2 w-full"
                      >
                        <div className="w-4/5">
                          <input
                            className="text-area"
                            placeholder={`${item.age}`}
                            name={`ages[${index}].age`}
                            ref={register({ required: true })}
                            key={item.id}
                            onFocus={(e) => (e.target.value = "")}
                          ></input>
                        </div>
                        <button
                          type="button"
                          onClick={() => {
                            agesRemove(index);
                          }}
                        >
                          X
                        </button>
                      </li>
                    );
                  })}
                </ul>
                <section className="my-4">
                  <button
                    className="font-normal"
                    type="button"
                    onClick={() => {
                      agesAppend({
                        age: "10",
                      });
                    }}
                  >
                    + Add More
                  </button>
                </section>
              </div>
            ) : null}

            <div className="mb-4">
              <label className="form-label">
                Are you actively trying to conceive?
              </label>
              <select
                className="text-box"
                name="conceive"
                ref={register({ required: true })}
              >
                <option value="1">Yes</option>
                <option value="0" selected={true}>
                  No
                </option>
              </select>
            </div>

            {watchConceive && watchConceive === "1" ? (
              <div className="grid grid-cols-1 md:grid-cols-2 gap-2 mb-8 pl-4">
                <div className="mb-4">
                  <label className="form-label-light">
                    How long have you been actively trying? (years)
                  </label>

                  <input
                    name="actively_trying"
                    ref={register({ required: true })}
                    className="text-area"
                    placeholder="How long"
                    autoCapitalize="none"
                  ></input>
                </div>
                <div className="mb-4">
                  <label className="form-label-light">
                    Have you sought reproductive assistance?
                  </label>
                  <select
                    className="text-box"
                    name="reproductive_assistance"
                    ref={register({ required: true })}
                  >
                    <option value="1">Yes</option>
                    <option value="0" selected={true}>
                      No
                    </option>
                  </select>
                </div>
              </div>
            ) : null}

            <div className="mb-4">
              <label className="form-label">
                Do you have any known allergies?
              </label>
              <select
                className="text-box"
                name="allergies"
                ref={register({ required: true })}
              >
                <option value="1">Yes</option>
                <option value="0" selected={true}>
                  No
                </option>
              </select>
            </div>

            {watchAllergies === "1" ? (
              <div className="mb-4 pl-4">
                <label className="form-label-light">
                  Please list your allergies and define what happens upon
                  contact/ingestion*
                </label>
                <ul>
                  {allergiesFields.map((item, index) => {
                    return (
                      <li
                        key={item.id}
                        className="my-4 flex flex-row gap-2 w-full"
                      >
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-2 w-4/5">
                          <input
                            className="text-area"
                            defaultValue={`${item.allergy}`}
                            name={`allergy_list[${index}].allergy`}
                            ref={register({ required: true })}
                            key={`${item.id}_allergy`}
                            onFocus={(e) => (e.target.value = "")}
                          ></input>
                          <input
                            className="text-area"
                            defaultValue={`${item.reaction}`}
                            name={`allergy_list[${index}].reaction`}
                            ref={register({ required: true })}
                            key={`${item.id}_reaction`}
                            onFocus={(e) => (e.target.value = "")}
                          ></input>
                        </div>
                        <button
                          type="button"
                          onClick={() => {
                            allergiesRemove(index);
                          }}
                        >
                          X
                        </button>
                      </li>
                    );
                  })}
                </ul>
                <section className="my-4">
                  <button
                    className="font-normal"
                    type="button"
                    onClick={() => {
                      allergiesAppend({
                        allergy: "Allergy",
                        reaction: "Reaction",
                      });
                    }}
                  >
                    + Add More
                  </button>
                </section>
              </div>
            ) : null}

            <div className="mb-4">
              <label className="form-label">Are you a smoker?</label>
              <select
                className="text-box"
                name="smoker"
                ref={register({ required: true })}
              >
                <option value="1">Yes</option>
                <option value="0" selected={true}>
                  No
                </option>
              </select>
            </div>

            {watchSmoker && watchSmoker === "1" ? (
              <div className="grid grid-cols-1 md:grid-cols-2 gap-2 mb-8 pl-4">
                <div>
                  <label className="form-label-light">Quantity per day*</label>
                  <input
                    name="quantity_per_day"
                    ref={register({ required: true })}
                    className="text-box"
                    placeholder="Number smoked per day"
                    autoCapitalize="none"
                  ></input>
                </div>
                <div>
                  <label className="form-label-light">
                    Are you looking to stop?
                  </label>
                  <select
                    className="text-box"
                    name="seeking_to_stop"
                    ref={register({ required: true })}
                  >
                    <option value="1" selected={true}>
                      Yes
                    </option>
                    <option value="0">No</option>
                  </select>
                </div>
              </div>
            ) : null}

            <div className="mb-4">
              <label className="form-label">
                Have you had any previous fractures and/or surgeries?
              </label>
              <select
                className="text-box"
                name="fractures_or_surgeries"
                ref={register({ required: true })}
              >
                <option value="1">Yes</option>
                <option value="0" selected={true}>
                  No
                </option>
              </select>
            </div>

            {watchSurgery && watchSurgery === "1" ? (
              <div className="mb-4 pl-4">
                <label className="form-label-light">
                  List the body parts that were fractured and dates of injury
                  (including car accidents / motor vehicle trauma). Please also
                  list any past surgeries.
                </label>
                <ul>
                  {surgeryFields.map((item, index) => {
                    return (
                      <li
                        key={item.id}
                        className="my-4 flex flex-row gap-2 w-full"
                      >
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-2 w-4/5">
                          <input
                            className="text-area"
                            defaultValue={`${item.detail}`}
                            name={`surgeries_list[${index}].detail`}
                            ref={register({ required: true })}
                            key={`${item.id}detail`}
                            onFocus={(e) => (e.target.value = "")}
                          ></input>
                          <input
                            className="text-area"
                            defaultValue={`${item.date}`}
                            name={`surgeries_list[${index}].date`}
                            ref={register({ required: true })}
                            key={`${item.id}date`}
                            onFocus={(e) => (e.target.value = "")}
                          ></input>
                        </div>
                        <button
                          type="button"
                          onClick={() => {
                            surgeryRemove(index);
                          }}
                        >
                          X
                        </button>
                      </li>
                    );
                  })}
                </ul>
                <section className="my-4">
                  <button
                    className="font-normal"
                    type="button"
                    onClick={() => {
                      surgeryAppend({
                        detail: "Allergy",
                        date: "01/01/2019",
                      });
                    }}
                  >
                    + Add More
                  </button>
                </section>
              </div>
            ) : null}

            <div className="mb-4 mt-8">
              <button type="submit" className="btn-joy">
                {updating ? (
                  <FontAwesomeIcon icon={faCircleNotch} spin />
                ) : (
                  "Continue"
                )}
              </button>
            </div>
            <div className="flex justify-center w-full">
              <ProgressBar currentStep={2} maxSteps={4} />
            </div>
          </form>
        )}
      </div>
    </Layout>
  );
};

export default PreAssessmentOne;
