//TODO: Should only be able to access if client_details set

import { useContext, useEffect, useState } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { UserContext } from "../../context/userContext";

import Layout from "../../components/Layout";

import _ from "lodash";
import { eachDayOfInterval } from "date-fns";

import "react-modern-calendar-datepicker/lib/DatePicker.css";
import { Calendar } from "react-modern-calendar-datepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";

import { Mixpanel } from "../../utils/Mixpanel";

//TODO: Privateroute

const BookingDetails = () => {
  const history = useHistory();
  const user = useContext(UserContext);

  const [loading, setLoading] = useState(true);
  const [locations, setLocations] = useState([]);

  const [treatmentTypes, setTreatmentTypes] = useState([]);

  const [sessions, setSessions] = useState([]);
  const [checkingTimeSlots, setCheckingTimeSlots] = useState(false);
  const [timeSlots, setTimeSlots] = useState([]);
  const [dateRange, setDateRange] = useState({});

  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedTreatmentType, setSelectedTreatmentType] = useState(null);
  const [selectedDay, setSelectedDay] = useState(null);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);
  const [telehealth, setTelehealth] = useState(false);

  const [highlightAgreeTerms, setHighlightAgreeTerms] = useState(false);
  const [agreeTerms, setAgreeTerms] = useState(false);
  const [agreeCOVIDTerms, setAgreeCOVIDTerms] = useState(false);
  const [creatingBooking, setCreatingBooking] = useState(false);

  useEffect(() => {
    const init = async () => {
      if (!user.userDetails.id) {
        history.replace("/login");
      }
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_API_URL}/booking/locations`
        );
        if (res.status !== 200) {
          throw new Error("Couldnt grab the treatment type details");
        }

        setLocations(res.data);
      } catch (error) {
        window.alert(
          "We can't proceed with an online booking right now - please call us on 1300 859 785 to book an appointment"
        );
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    init();
    Mixpanel.track("View | Booking");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const getTreatments = async () => {
      await setLoading(true);
      try {
        setSelectedDay(null);
        const clientId = user.userDetails.id;

        const res = await axios.get(
          `https://bookings.endeavourclinic.com.au/booking/treatments?client_type_id=1&location_id=${selectedLocation.id}&client_id=${clientId}`
        );
        if (res.status !== 200) {
          throw new Error("Couldnt grab the treatment type details");
        }
        await setTreatmentTypes(res.data);
        document.getElementById("treatments-list").scrollIntoView({
          behavior: "smooth",
        });
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    if (selectedLocation && selectedLocation.id) {
      getTreatments();
      Mixpanel.track("Booking | Selected Location");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLocation]);

  useEffect(() => {
    const getSessions = async () => {
      await setLoading(true);
      try {
        // setSelectedDay(null);
        const res = await axios.get(
          `${process.env.REACT_APP_API_URL}/booking/available_sessions?client_id=${user.userDetails.id}&treatment_product_id=${selectedTreatmentType.id}&location_id=${selectedLocation.id}`
        );
        if (res.status !== 200) {
          throw new Error("Couldnt grab sessions");
        }

        //Group the returned sessions by date and store all IDs
        const datesList = _.uniq(
          res.data.map((session) => {
            //Remove Z as this forces UTC and dates from API aren't UTC
            const date = new Date(session.date_and_time.replace("Z", ""));

            const details = {
              id: session.id,
              date: date,
              telehealth: session.telehealth,
            };

            return details;
          })
        );

        const sessionEntries = Object.entries(_.groupBy(datesList, "date"));

        const finalSessions = sessionEntries.map((session) => {
          const details = {
            date: session[0],
            telehealth: session[1][0].telehealth,
            sessionsIds: session[1]
              .map((session) => {
                return session.id;
              })
              .toString(),
          };
          return details;
        });

        //Need a list of unavailable dates between first date and last date
        const minDate = new Date();
        const maxDate = new Date(finalSessions[finalSessions.length - 1].date);

        const datesBetween = eachDayOfInterval({
          start: minDate,
          end: maxDate,
        });

        let formattedFinalSessions = await finalSessions.map((date) => {
          const formattedDate = new Date(date.date);
          return {
            day: formattedDate.getDate(),
            month: formattedDate.getMonth() + 1,
            year: formattedDate.getFullYear(),
            date: `${formattedDate.getDate()}${
              formattedDate.getMonth() + 1
            }${formattedDate.getFullYear()}`,
          };
        });

        let formattedDatesBetween = await datesBetween.map((date) => {
          return {
            day: date.getDate(),
            month: date.getMonth() + 1,
            year: date.getFullYear(),
            date: `${date.getDate()}${
              date.getMonth() + 1
            }${date.getFullYear()}`,
          };
        });

        //TODO: What exactly is this doing?
        const datesToBlock = formattedDatesBetween.filter(
          (item) =>
            !formattedFinalSessions.some((other) => item.date === other.date)
        );

        setDateRange({
          minDate: {
            day: minDate.getDate(),
            month: minDate.getMonth() + 1,
            year: minDate.getFullYear(),
          },
          maxDate: {
            day: maxDate.getDate(),
            month: maxDate.getMonth() + 1,
            year: maxDate.getFullYear(),
          },
          datesBetween: datesBetween,
          formattedDatesBetween: formattedDatesBetween,
          datesToBlock: datesToBlock,
        });

        await setSessions(finalSessions);
        document.getElementById("sessions-list").scrollIntoView({
          behavior: "smooth",
        });
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    if (selectedTreatmentType && selectedTreatmentType.id) {
      getSessions(selectedTreatmentType.id);
      Mixpanel.track("Booking | Selected Treatment");
    }
    setSelectedDay();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTreatmentType]);

  //LOCATION
  const selectLocation = (location) => {
    setSelectedLocation(location);
    setSelectedTreatmentType(null);
    setSelectedTimeSlot(null);
    setSelectedDay();
  };

  const selectTreatment = async (treatment) => {
    await setSelectedTreatmentType(treatment);
    setTimeSlots([]);
  };

  const getTimeSlots = async (sessionIds) => {
    await setLoading(true);
    try {
      await setCheckingTimeSlots(true);
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/booking/day_availabilities?client_id=${user.userDetails.id}&treatment_product_id=${selectedTreatmentType.id}&session_ids=${sessionIds}&location_id=${selectedLocation.id}`
      );

      if (res.status !== 200) {
        throw new Error("No timeslots found");
      }

      await setTimeSlots(res.data);
      setCheckingTimeSlots(false);

      document.getElementById("timeslots-list").scrollIntoView({
        behavior: "smooth",
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const selectDate = async (date) => {
    setSelectedTimeSlot(null);
    setSelectedDay(date);

    //now lookup to find session Ids
    const thisDate = `${date.year}-${date.month}-${date.day}`;

    let sessionIds = [];

    // eslint-disable-next-line array-callback-return
    await sessions.map((session) => {
      const formatDate = new Date(session.date);

      const date = `${formatDate.getFullYear()}-${
        formatDate.getMonth() + 1
      }-${formatDate.getDate()}`;

      if (thisDate === date) {
        sessionIds.push(session.sessionsIds);

        setTelehealth(session.telehealth === 1);
      }
    });

    //turns array into comma seperated string
    getTimeSlots(sessionIds.toString());
    Mixpanel.track("Booking | Selected Date");
  };

  const selectTimeSlot = async (timeSlot) => {
    await setSelectedTimeSlot(timeSlot);

    document.getElementById("confirmation").scrollIntoView({
      behavior: "smooth",
    });

    Mixpanel.track("Booking | Selected Time Slot");
  };

  const selectNewTimeSlot = async () => {
    setSelectedDay();
    setSelectedTimeSlot();
    setTimeSlots();
    document.getElementById("sessions-list").scrollIntoView({
      behavior: "smooth",
    });
  };

  //Complete Booking
  const completeBooking = async () => {
    try {
      await setCreatingBooking(true);

      const payload = {
        key: selectedTimeSlot.key,
        client_id: user.userDetails.id,
      };

      const res = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_API_URL}/booking/create_booking`,
        data: payload,
      });

      if (!res.data.booking.id) {
        throw new Error(
          "Something went wrong - please try again or call 1300 859 785 and we will be happy to help"
        );
      }

      /* 
      TODO: The logic here is "If we are taking payment online then don't call this"

      I expect the "telehealth" rule below to change because we will most likely end up taking payments for all non-inperson bookings. In that case, you'll probably need to set this to 'if payment value === 0'.
     
      */

      if (!telehealth || selectedTimeSlot.price_in_cents === 0) {
        const resBookingConfirm = await axios.post(
          `${process.env.REACT_APP_API_URL}/booking/confirm?booking_id=${res.data.booking.id}`
        );
        if (resBookingConfirm.data.error) {
          throw new Error(
            "Something went wrong confirmation your booking - please try again or call 1300 859 785 and we will be happy to help"
          );
        }
      }

      Mixpanel.track("Booking | Booking Confirmed");

      const bookingDetails = {
        timeSlot: selectedTimeSlot,
        treatment: selectedTreatmentType,
        location: selectedLocation,
        date: selectedDay,
        telehealth: telehealth,
        paymentRequired: selectedTimeSlot.price_in_cents > 0 && telehealth, //TODO: this will probably need to change to `payment value > 0` (i.e. remove telehealth) once we start taking payment for all non-inperson bookings
        bookingId: res.data.booking.id,
      };

      await user.setBookingDetails(bookingDetails);

      await setCreatingBooking(false);

      if (bookingDetails.paymentRequired) {
        history.replace(`/payment?booking_id=${bookingDetails.bookingId}`);
      } else {
        history.replace("/complete");
      }
    } catch (error) {
      setCreatingBooking(false);
      console.log(error);
      alert(
        "Something went wrong - please contact us on 1300 859 785 to complete your booking"
      );
    }
  };

  return (
    <Layout>
      <div className="w-full">
        {locations.length ? (
          <div id="locations-list" className="py-16 ">
            <div className="text-center py-4">
              <p className="text-4xl font-semibold text-offwhite">
                Hi {user.userDetails.first_name}. Which clinic would you like to
                visit?
              </p>
            </div>
            <div className="p-8 grid grid-cols-1 md:grid-cols-3 gap-2">
              {locations.map((location, index) => (
                <div
                  key={location.name}
                  onClick={() => selectLocation(location)}
                  className={
                    "h-40 rounded bg-offwhite text-black  cursor-pointer hover:bg-joy hover:text-offwhite p-2 shadow-md " +
                    (selectedLocation && location.id === selectedLocation.id
                      ? " bg-joy text-white"
                      : " bg-offwhite")
                  }
                >
                  <div className="flex justify-center items-center w-full h-full m-auto  flex-col ">
                    {selectedLocation &&
                    location.id === selectedLocation.id &&
                    loading ? (
                      <FontAwesomeIcon
                        icon={faCircleNotch}
                        spin
                        className="text-2xl"
                      />
                    ) : (
                      <>
                        <p className="text-2xl text-center">{location.name}</p>
                        <p>{location.street_address}</p>
                        <p>{location.postcode}</p>
                      </>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : null}

        {treatmentTypes.length ? (
          <div id="treatments-list" className="py-16">
            <div className="text-center py-4">
              <p className="text-4xl font-semibold text-offwhite">
                Which treatment would you like at our {selectedLocation.name}{" "}
                clinic?
              </p>
            </div>
            <div className="p-8 grid grid-cols-1 md:grid-cols-3 gap-2">
              {treatmentTypes.map((treatment, index) => (
                <div
                  key={treatment.name}
                  onClick={() => selectTreatment(treatment)}
                  className={
                    "h-40 rounded bg-offwhite cursor-pointer hover:bg-joy hover:text-offwhite p-2  shadow-md " +
                    (selectedTreatmentType &&
                    treatment.id === selectedTreatmentType.id
                      ? " bg-joy text-white"
                      : " bg-offwhite")
                  }
                >
                  <div className="flex justify-center items-center w-full h-full m-auto  flex-col ">
                    {selectedTreatmentType &&
                    treatment.id === selectedTreatmentType.id &&
                    loading ? (
                      <FontAwesomeIcon
                        icon={faCircleNotch}
                        spin
                        className="text-2xl"
                      />
                    ) : (
                      <>
                        <p className="text-2xl text-center">{treatment.name}</p>
                        <p>
                          {treatment.duration} mins / $
                          {treatment.price_in_cents / 100}
                        </p>
                      </>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : null}
        {!creatingBooking && sessions.length ? (
          <div id="sessions-list" className="py-16">
            <div className="text-center py-4">
              <p className="text-4xl font-semibold text-offwhite">
                When would you like to book&nbsp;for?
              </p>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2">
              <div className="w-full flex justify-center flex-col">
                <p className="text-2xl text-center text-offwhite">
                  Select a date
                </p>
                <div className="flex justify-center p-8">
                  <Calendar
                    value={selectedDay}
                    onChange={(date) => selectDate(date)}
                    minimumDate={dateRange.minDate}
                    maximumDate={dateRange.maxDate}
                    disabledDays={dateRange.datesToBlock}
                    inputPlaceholder="Select a date"
                    calendarClassName="responsive-calendar"
                  />
                </div>
              </div>
              <div className="w-full">
                {selectedDay ? (
                  timeSlots && timeSlots.length ? (
                    <div id="timeslots-list" className="py-16 md:py-0">
                      <p className="text-2xl text-center text-offwhite">
                        Select a timeslot for {selectedDay.day}/
                        {selectedDay.month}/{selectedDay.year}
                      </p>
                      <div className="p-8 grid grid-cols-2 gap-4">
                        {timeSlots.map((timeSlot, index) => (
                          <div
                            key={timeSlot.label}
                            onClick={() => selectTimeSlot(timeSlot)}
                            className={
                              "w-full h-16 rounded  cursor-pointer hover:bg-joy hover:text-offwhite flex justify-center items-center shadow-md " +
                              (timeSlot === selectedTimeSlot
                                ? " bg-joy text-offwhite"
                                : "bg-offwhite text-black")
                            }
                          >
                            <p className="text-sm font-semibold">
                              {timeSlot.label}
                            </p>
                          </div>
                        ))}
                        <div
                          onClick={() => selectNewTimeSlot()}
                          className="w-full h-16 rounded bg-offwhite cursor-pointer text-black hover:bg-joy hover:text-offwhite mb-4 flex justify-center items-center  shadow-md"
                        >
                          <p className="text-sm font-semibold text-center">
                            Select Different Date
                          </p>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div
                      id="timeslots-list"
                      className="py-16 md:py-0 flex items-center justify-center h-full"
                    >
                      {checkingTimeSlots ? (
                        <div className="text-5xl text-center text-offwhite bg-none p-4 w-full h-auto">
                          <FontAwesomeIcon icon={faCircleNotch} spin />
                        </div>
                      ) : (
                        <div className="text-2xl text-center text-offwhite bg-joy p-4 w-full h-auto rounded">
                          <p className="font-semibold mb-2">
                            No Timeslots Available
                          </p>
                          <p>Select a different date</p>
                        </div>
                      )}
                    </div>
                  )
                ) : null}
              </div>
            </div>
          </div>
        ) : null}

        {selectedTimeSlot && selectedTimeSlot.key && selectedDay ? (
          <div
            id="confirmation"
            className="p-4 md:p-16 pb-32 bg-white rounded  shadow-md mb-16"
          >
            <div className="text-center py-2 md:px-16 mb-8">
              <p className="text-4xl font-semibold mb-4">
                Thanks {user.userDetails.first_name}
              </p>
              <p className="text-2xl font-semibold">
                Here's a summary of your appointment.
              </p>
            </div>
            <div className="px-4">
              <p className="pb-4">
                You've chosen a{" "}
                <span className="font-semibold">
                  {selectedTreatmentType.name}
                </span>{" "}
                treatment on{" "}
                <span className="font-semibold">
                  {selectedDay.day}/{selectedDay.month}/{selectedDay.year}
                </span>{" "}
                at{" "}
                <span className="font-semibold">{selectedTimeSlot.label}</span>
              </p>

              <p>
                Your session will last for approximately{" "}
                <span className="font-semibold">
                  {selectedTimeSlot.duration_in_minutes} minutes.
                </span>
              </p>
              {selectedTreatmentType.id === 74 ||
              selectedTreatmentType.id === 78 ? (
                //If a VET treatment
                <p>
                  You can expect up to 40mins of physical treatment in a one
                  hour remedial and/or relaxation consult.
                </p>
              ) : null}

              {selectedTimeSlot.price_in_cents > 0 ? (
                <>
                  <p className="mt-4">
                    Your appointment will cost{" "}
                    <span className="font-semibold">
                      ${selectedTimeSlot.price_in_cents / 100}
                    </span>{" "}
                    {telehealth &&
                    process.env.REACT_APP_ALLOW_PAYMENTS === "true" ? (
                      <span>
                        which is{" "}
                        <span className="font-semibold">payable today.</span>
                      </span>
                    ) : (
                      <>
                        <span>
                          which is{" "}
                          <span className="font-semibold">
                            payable via EFTPOS
                          </span>{" "}
                          on the day.
                        </span>

                        <span className="font-semibold">
                          {" "}
                          We are a cashless clinic.
                        </span>
                      </>
                    )}
                  </p>
                </>
              ) : (
                <p className="mt-4">There is no charge for this appointment</p>
              )}
            </div>
            <form className="my-8 px-4 flex flex-col text-sm">
              <span className="mb-4">
                <input
                  type="checkbox"
                  onChange={() => setAgreeTerms(!agreeTerms)}
                ></input>{" "}
                <span
                  className={
                    highlightAgreeTerms
                      ? "underline font-semibold"
                      : "font-semibold"
                  }
                >
                  I agree to the{" "}
                  <a
                    href="https://www.endeavourclinic.com.au/cancellation-policy/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="underline"
                  >
                    Cancellation Policy
                  </a>{" "}
                  and{" "}
                  <a
                    href="https://www.endeavourclinic.com.au/terms-and-conditions/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="underline"
                  >
                    Terms and Conditions
                  </a>
                </span>
              </span>
              <span>
                <input
                  type="checkbox"
                  onChange={() => setAgreeCOVIDTerms(!agreeCOVIDTerms)}
                ></input>{" "}
                <span
                  className={
                    highlightAgreeTerms
                      ? "underline font-semibold"
                      : "font-semibold"
                  }
                >
                  I may be treated by students and/or supervising practitioners
                  that have not received any or all of the prescribed number of
                  doses of the COVID-19 vaccine. I accept that this may present
                  a risk to my health, including by exposing me to, and/or
                  putting me at greater risk of contracting, COVID-19. As such,
                  and to the fullest extent permitted by law, I release
                  Endeavour College of Natural Health and each of its officers,
                  directors, employees and agents from any and all liability,
                  loss, or personal injury suffered or incurred arising out of,
                  or in connection with, that risk. Endeavour will comply with
                  their obligations under the public health orders of each state
                  and territory by ensuring that its practitioners and students
                  are fully vaccinated where any such order requires them to be.
                </span>
              </span>
            </form>
            <div className="px-4">
              <button
                disabled={!agreeTerms || !agreeCOVIDTerms || creatingBooking}
                onClick={() =>
                  agreeTerms && agreeCOVIDTerms
                    ? completeBooking()
                    : setHighlightAgreeTerms(true)
                }
                className={
                  "btn-joy " +
                  (agreeCOVIDTerms && agreeTerms ? "" : "opacity-50")
                }
                id="book-now"
              >
                {agreeTerms} {agreeCOVIDTerms}
                {creatingBooking ? (
                  <FontAwesomeIcon icon={faCircleNotch} spin />
                ) : (
                  "Book Now"
                )}
              </button>
            </div>
          </div>
        ) : null}
      </div>
    </Layout>
  );
};

export default BookingDetails;
