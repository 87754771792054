import { useContext, useEffect, useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";

import { UserContext } from "../../../context/userContext";
import Layout from "../../../components/Layout";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { Mixpanel } from "../../../utils/Mixpanel";
import * as Sentry from "@sentry/react";

import ProgressBar from "../../../components/ProgressBar";

const PreAssessmentOne = () => {
  const history = useHistory();
  const user = useContext(UserContext);
  const [updating, setUpdating] = useState(false);
  const [error, setError] = useState();

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const updateUser = async (data) => {
    if (!user.userDetails.email) {
      setError("No email found - exiting");
    }

    try {
      setUpdating(true);
      setError();

      //Anything thats false should be set to 0 and 1 should be int
      for (const val in user.preassessment) {
        if (user.preassessment[val] === false) {
          user.preassessment[val] = 0;
        }
        if (user.preassessment[val] === "1") {
          user.preassessment[val] = 1;
        }
      }

      let formData = new FormData();

      formData.append("client[email]", user.userDetails.email);
      for (const val in user.preassessment) {
        formData.append(
          `assessment[${val}]`,
          JSON.stringify(user.preassessment[val])
        );
      }

      formData.append("assessment[consent_given]", "1");

      const res = await axios({
        method: "POST",
        url: process.env.REACT_APP_API_URL + "/client_preassessment/new",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data;",
        },
      });

      if (res.data.status && res.data.status === 404) {
        throw new Error(res.data.reply);
      }
      Mixpanel.track("Client Registration Form | Preassesment Three | Success");
      history.push("/client-registration/complete");
    } catch (error) {
      setUpdating(false);

      await setError(error.message);

      document.getElementById("form").scrollIntoView({
        behavior: "smooth",
      });

      Sentry.captureException(error);

      Mixpanel.track("Client Registration Form | Preassesment Three | Failure");
    } finally {
      setTimeout(() => {
        setUpdating(false);
      }, 1000);
    }
  };

  useEffect(() => {
    Mixpanel.track("Client Registration Form | Preassesment One | View");
    if (!user.userDetails.id) {
      //send to login with param attached
      history.push({
        pathname: "/login",
        search: `?prev=/client-registration/preassessment/1`,
      });
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout>
      <div className="preAssessmentm-auto py-8 px-0 md:px-4 w-full md:w-3/4 lg:w-3/4 xl:w-2/3 h-auto my-8">
        {!user.userDetails.email ? (
          <div className="mb-4 bg-offwhite p-4 md:p-8 rounded">
            <p className="text-center font-semibold">Something went wrong</p>
          </div>
        ) : (
          <form
            onSubmit={handleSubmit(updateUser)}
            className="mb-4 bg-offwhite p-8 md:p-8 rounded"
            id="form"
          >
            {error ? (
              <div
                className="flex items-center bg-joy text-offwhite text-sm font-bold px-4 py-3 w-full mb-8 mt-8 rounded"
                role="alert"
                id="error"
              >
                <svg
                  className="fill-current w-4 h-4 mr-2"
                  xmlns="https://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M12.432 0c1.34 0 2.01.912 2.01 1.957 0 1.305-1.164 2.512-2.679 2.512-1.269 0-2.009-.75-1.974-1.99C9.789 1.436 10.67 0 12.432 0zM8.309 20c-1.058 0-1.833-.652-1.093-3.524l1.214-5.092c.211-.814.246-1.141 0-1.141-.317 0-1.689.562-2.502 1.117l-.528-.88c2.572-2.186 5.531-3.467 6.801-3.467 1.057 0 1.233 1.273.705 3.23l-1.391 5.352c-.246.945-.141 1.271.106 1.271.317 0 1.357-.392 2.379-1.207l.6.814C12.098 19.02 9.365 20 8.309 20z" />
                </svg>
                <div className="flex justify-center flex-col w-full">
                  <p className="mb-2">{error}</p>
                </div>
              </div>
            ) : null}

            <p className="text-3xl font-bold mb-4">
              Preassesment Final for {user.userDetails.first_name}
            </p>

            <p className="font-semibold mb-4">
              I, the undersigned, declare that I understand:*
            </p>

            <ul className="list-disc mb-8">
              <li>
                This is a student clinic, not a medical practice and as such it
                is supervised by a qualified practitioner of the discipline
              </li>

              <li>
                The supervising practitioner and/or an observing student may be
                present in the consultation room during my appointment
              </li>

              <li>
                At any time during my clinic appointment, I can request to see
                the supervising practitioner
              </li>

              <li>Check this box to accept the above terms</li>

              <li>
                As this is a teaching clinic, it may not be possible to see the
                same student practitioner at each visit. However, all efforts to
                facilitate this will be made
              </li>

              <li>
                Consultations and treatments vary in length, but may take up to
                90 minutes
              </li>

              <li>
                The appointment time includes consultation time, student review
                and feedback with the clinic supervisor, and student feedback
                regarding any recommended treatment plan
              </li>

              <li>
                The physical examination I receive may involve partial
                undressing and having the student/supervising practitioner
                palpate (i.e. touch) me
              </li>

              <li>
                I may choose to terminate the appointment at any time, but may
                be required to pay in full for the consult according to the
                Endeavour Wellness Clinic Cancellation Policy
              </li>

              <li>
                My health records are confidential and will be used for training
                and/or research purposes only. Under no circumstances will my
                file leave the grounds of Endeavour College of Natural Health
                (unless otherwise approved, as below)
              </li>

              <li>
                My de-identified case notes may be used for discussion,
                teaching, research and examination purposes
              </li>

              <li>
                Cumulative results from case files may be used for research
                purposes however individuals cases will not be used for case
                report publication/s without your prior specific written consent
              </li>

              <li>
                It may be necessary from time to time for my case to be
                discussed with other health professionals (e.g., general
                practitioners, medical specialists and/or complementary medicine
                practitioners) and provide my consent for part/all of my medical
                case notes to be released for these purposes only
              </li>

              <li>
                I may be treated by students and/or supervising practitioners
                that have not received any or all of the prescribed number of
                doses of the COVID-19 vaccine. I accept that this may present a
                risk to my health, including by exposing me to, and/or putting
                me at greater risk of contracting, COVID-19. As such, and to the
                fullest extent permitted by law, I release Endeavour College of
                Natural Health and each of its officers, directors, employees
                and agents from any and all liability, loss, or personal injury
                suffered or incurred arising out of, or in connection with, that
                risk. Endeavour will comply with their obligations under the
                public health orders of each state and territory by ensuring
                that its practitioners and students are fully vaccinated where
                any such order requires them to be.”
              </li>
            </ul>

            <section className="mb-4">
              <input
                name="terms"
                type="checkbox"
                className="form-checkbox"
                style={{ flex: 1 }}
                ref={register({ required: true, minLength: 1 })}
                value="1"
              ></input>
              <label className="justify-start" style={{ flex: 2 }}>
                I accept and agree to the above terms
              </label>
              {errors.terms ? (
                <span className="form-field-error mb-4">
                  {errors.terms && "You must check this box"}
                </span>
              ) : null}
            </section>

            <section className="mb-8">
              <input
                name="cancellation"
                type="checkbox"
                className="form-checkbox"
                style={{ flex: 1 }}
                ref={register({ required: true, minLength: 1 })}
                value="1"
              ></input>
              <label className="" style={{ flex: 2 }}>
                I accept and agree to the Endeavour Wellness Clinic Cancellation
                Policy and Endeavour Wellness Clinic Refund Policy
              </label>
              {errors.cancellation ? (
                <span className="form-field-error">
                  {errors.cancellation && "You must check this box"}
                </span>
              ) : null}
            </section>

            <section className="mb-2">
              Please click Finish below to sign this document and complete the
              form.
            </section>

            <div className="mb-4 mt-2">
              <button type="submit" className="btn-joy">
                {updating ? (
                  <FontAwesomeIcon icon={faCircleNotch} spin />
                ) : (
                  "Finish"
                )}
              </button>
            </div>
            <div className="flex justify-center w-full">
              <ProgressBar currentStep={4} maxSteps={4} />
            </div>
          </form>
        )}
      </div>
    </Layout>
  );
};

export default PreAssessmentOne;
