const preAssessmentTwoSchema = [
  {
    condition_id: "pain_neck",
    condition_category: "Pain / Stiffness",
    condition_label: "Neck / Jaw",
    field_type: "boolean",
  },
  {
    condition_id: "pain_back",
    condition_category: "Pain / Stiffness",
    condition_label: "Back",
    field_type: "boolean",
  },
  {
    condition_id: "pain_shoulder",
    condition_category: "Pain / Stiffness",
    condition_label: "Shoulder / Arm / Hand",
    field_type: "boolean",
  },
  {
    condition_id: "pain_leg",
    condition_category: "Pain / Stiffness",
    condition_label: "Leg / Feet / Toes",
    field_type: "boolean",
  },
  {
    condition_id: "pain_night",
    condition_category: "Pain / Stiffness",
    condition_label: "At Night",
    field_type: "boolean",
  },
  {
    condition_id: "pain_morning",
    condition_category: "Pain / Stiffness",
    condition_label: "Morning",
    field_type: "boolean",
  },
  {
    condition_id: "pins_arms",
    condition_category: "Pins & Needles / Tingling",
    condition_label: "Arms / Hands / Fingers",
    field_type: "boolean",
  },
  {
    condition_id: "pins_legs",
    condition_category: "Pins & Needles / Tingling",
    condition_label: "Legs / Feet / Toes",
    field_type: "boolean",
  },
  {
    condition_id: "pins_other",
    condition_category: "Pins & Needles / Tingling",
    condition_label: "Other",
    field_type: "boolean",
  },
  {
    condition_id: "numbness_arms",
    condition_category: "Numbness",
    condition_label: "Arms / Hands",
    field_type: "boolean",
  },
  {
    condition_id: "numbness_legs",
    condition_category: "Numbness",
    condition_label: "Legs / Feet",
    field_type: "boolean",
  },
  {
    condition_id: "numbness_other",
    condition_category: "Numbness",
    condition_label: "Other",
    field_type: "boolean",
  },
  {
    condition_id: "cold_hands",
    condition_category: "Cold Extremities",
    condition_label: "Hands",
    field_type: "boolean",
  },
  {
    condition_id: "cold_feet",
    condition_category: "Cold Extremities",
    condition_label: "Legs / Feet",
    field_type: "boolean",
  },
  {
    condition_id: "swelling_hands",
    condition_category: "Swelling",
    condition_label: "Arms / Hands",
    field_type: "boolean",
  },
  {
    condition_id: "swelling_feet",
    condition_category: "Swelling",
    condition_label: "Legs / Feet",
    field_type: "boolean",
  },
  {
    condition_id: "balance_weakness",
    condition_category: "Balance",
    condition_label: "Weakness / Clumsiness",
    field_type: "boolean",
  },
  {
    condition_id: "balance_loss",
    condition_category: "Balance",
    condition_label: "Loss of Balance",
    field_type: "boolean",
  },
  {
    condition_id: "balance_vertigo",
    condition_category: "Balance",
    condition_label: "Vertigo",
    field_type: "boolean",
  },
  {
    condition_id: "respiratory_breathing",
    condition_category: "Respiratory System",
    condition_label: "Difficulty Breathing",
    field_type: "boolean",
  },
  {
    condition_id: "respiratory_cough",
    condition_category: "Respiratory System",
    condition_label: "Cough",
    field_type: "boolean",
  },
  {
    condition_id: "respiratory_chronic",
    condition_category: "Respiratory System",
    condition_label: "Chronic Cough",
    field_type: "boolean",
  },
  {
    condition_id: "respiratory_sinus",
    condition_category: "Respiratory System",
    condition_label: "Sinus Problems",
    field_type: "boolean",
  },
  {
    condition_id: "respiratory_hay_fever",
    condition_category: "Respiratory System",
    condition_label: "Hay Fever",
    field_type: "boolean",
  },
  {
    condition_id: "respiratory_asthma",
    condition_category: "Respiratory System",
    condition_label: "Asthma",
    field_type: "boolean",
  },
  {
    condition_id: "respiratory_bronchitis",
    condition_category: "Respiratory System",
    condition_label: "Chronic Bronchitis",
    field_type: "boolean",
  },
  {
    condition_id: "digestive_diarrhoea",
    condition_category: "Digestive System",
    condition_label: "Diarrhoea",
    field_type: "boolean",
  },
  {
    condition_id: "digestive_ulcers",
    condition_category: "Digestive System",
    condition_label: "Ulcers",
    field_type: "boolean",
  },
  {
    condition_id: "digestive_nausea",
    condition_category: "Digestive System",
    condition_label: "Nausea / Appetite",
    field_type: "boolean",
  },
  {
    condition_id: "digestive_constipation",
    condition_category: "Digestive System",
    condition_label: "Constipation",
    field_type: "boolean",
  },
  {
    condition_id: "digestive_discomfort",
    condition_category: "Digestive System",
    condition_label: "Abdominal Discomfort",
    field_type: "boolean",
  },
  {
    condition_id: "digestive_indigestion",
    condition_category: "Digestive System",
    condition_label: "Indigestion",
    field_type: "boolean",
  },
  {
    condition_id: "digestive_reflux",
    condition_category: "Digestive System",
    condition_label: "Reflux",
    field_type: "boolean",
  },
  {
    condition_id: "renal_frequent",
    condition_category: "Renal System",
    condition_label: "Frequent Urination",
    field_type: "boolean",
  },
  {
    condition_id: "renal_poor",
    condition_category: "Renal System",
    condition_label: "Poor Urine Stream",
    field_type: "boolean",
  },
  {
    condition_id: "renal_incomplete",
    condition_category: "Renal System",
    condition_label: "Feeling of incomplete emptying",
    field_type: "boolean",
  },
  {
    condition_id: "renal_pain",
    condition_category: "Renal System",
    condition_label: "Urinacry tract pain",
    field_type: "boolean",
  },
  {
    condition_id: "renal_incontinence",
    condition_category: "Renal System",
    condition_label: "Incontinence",
    field_type: "boolean",
  },
  {
    condition_id: "renal_cystitis",
    condition_category: "Renal System",
    condition_label: "Cystitis",
    field_type: "boolean",
  },
  {
    condition_id: "immune_colds",
    condition_category: "Immune System",
    condition_label: "Frequent Colds / Flu",
    field_type: "boolean",
  },
  {
    condition_id: "immune_uti",
    condition_category: "Immune System",
    condition_label: "Frequent UTIs",
    field_type: "boolean",
  },
  {
    condition_id: "immune_fatigue",
    condition_category: "Immune System",
    condition_label: "Chronic Fatigue",
    field_type: "boolean",
  },
  {
    condition_id: "immune_fibromyalgia",
    condition_category: "Immune System",
    condition_label: "Fibromyalgia",
    field_type: "boolean",
  },
  {
    condition_id: "immune_ibd",
    condition_category: "Immune System",
    condition_label: "IDB - Crohns or UC",
    field_type: "boolean",
  },
  {
    condition_id: "immune_sclerosis",
    condition_category: "Immune System",
    condition_label: "Multiple Sclerosis",
    field_type: "boolean",
  },
  {
    condition_id: "immune_glandular",
    condition_category: "Immune System",
    condition_label: "Glandular Fever",
    field_type: "boolean",
  },
  {
    condition_id: "immune_allergies",
    condition_category: "Immune System",
    condition_label: "Allergies",
    field_type: "boolean",
  },
  {
    condition_id: "heart_chest",
    condition_category: "Heart",
    condition_label: "Chest Pain",
    field_type: "boolean",
  },
  {
    condition_id: "heart_angina",
    condition_category: "Heart",
    condition_label: "Heart Problems / Angina",
    field_type: "boolean",
  },
  {
    condition_id: "heart_pacemaker",
    condition_category: "Heart",
    condition_label: "Pacemaker",
    field_type: "boolean",
  },
  {
    condition_id: "heart_high",
    condition_category: "Heart",
    condition_label: "High Blood Pressure",
    field_type: "boolean",
  },
  {
    condition_id: "heart_low",
    condition_category: "Heart",
    condition_label: "Low Blood Pressure",
    field_type: "boolean",
  },
  {
    condition_id: "heart_varicose",
    condition_category: "Heart",
    condition_label: "Varicose Veins",
    field_type: "boolean",
  },
  {
    condition_id: "heart_clots",
    condition_category: "Heart",
    condition_label: "Blood Clots / DVT",
    field_type: "boolean",
  },
  {
    condition_id: "heart_stroke",
    condition_category: "Heart",
    condition_label: "Stroke (CVA)",
    field_type: "boolean",
  },
  {
    condition_id: "skin_rashes",
    condition_category: "Skin",
    condition_label: "Rashes",
    field_type: "boolean",
  },
  {
    condition_id: "skin_itching",
    condition_category: "Skin",
    condition_label: "Itching",
    field_type: "boolean",
  },
  {
    condition_id: "skin_wounds",
    condition_category: "Skin",
    condition_label: "Wounds slow to heal",
    field_type: "boolean",
  },
  {
    condition_id: "skin_hives",
    condition_category: "Skin",
    condition_label: "Hives",
    field_type: "boolean",
  },
  {
    condition_id: "skin_bruise",
    condition_category: "Skin",
    condition_label: "Bruise Easily",
    field_type: "boolean",
  },
  {
    condition_id: "skin_dermatitis",
    condition_category: "Skin",
    condition_label: "Dermatitis",
    field_type: "boolean",
  },
  {
    condition_id: "skin_psoriasis",
    condition_category: "Skin",
    condition_label: "Psoriasis",
    field_type: "boolean",
  },
  {
    condition_id: "skin_eczema",
    condition_category: "Skin",
    condition_label: "Eczema",
    field_type: "boolean",
  },
  {
    condition_id: "neurological_confusion",
    condition_category: "Neurological",
    condition_label: "Confusion",
    field_type: "boolean",
  },
  {
    condition_id: "neurological_memory",
    condition_category: "Neurological",
    condition_label: "Memory Loss",
    field_type: "boolean",
  },
  {
    condition_id: "neurological_alertness",
    condition_category: "Neurological",
    condition_label: "Altered Alertness",
    field_type: "boolean",
  },
  {
    condition_id: "neurological_vision",
    condition_category: "Neurological",
    condition_label: "Changes in Vision",
    field_type: "boolean",
  },
  {
    condition_id: "neurological_cramps",
    condition_category: "Neurological",
    condition_label: "Muscle Cramps",
    field_type: "boolean",
  },
  {
    condition_id: "neurological_seizures",
    condition_category: "Neurological",
    condition_label: "Seizures",
    field_type: "boolean",
  },
  {
    condition_id: "neurological_fatigue",
    condition_category: "Neurological",
    condition_label: "Neurological Fatigue",
    field_type: "boolean",
  },
  {
    condition_id: "neurological_weakness",
    condition_category: "Neurological",
    condition_label: "Weakness",
    field_type: "boolean",
  },
  {
    condition_id: "neurological_depression",
    condition_category: "Neurological",
    condition_label: "Depression / Anxiety",
    field_type: "boolean",
  },
  {
    condition_id: "neurological_mental",
    condition_category: "Neurological",
    condition_label: "Mental Health Issues (Other)",
    field_type: "boolean",
  },
  {
    condition_id: "senses_headache",
    condition_category: "Senses",
    condition_label: "Headache",
    field_type: "boolean",
  },
  {
    condition_id: "senses_dizziness",
    condition_category: "Senses",
    condition_label: "Dizziness / Light Headed",
    field_type: "boolean",
  },
  {
    condition_id: "senses_heavy",
    condition_category: "Senses",
    condition_label: "Heavy Headed",
    field_type: "boolean",
  },
  {
    condition_id: "senses_fainting",
    condition_category: "Senses",
    condition_label: "Fainting",
    field_type: "boolean",
  },
  {
    condition_id: "senses_light",
    condition_category: "Senses",
    condition_label: "Visual Light Sensitivity",
    field_type: "boolean",
  },
  {
    condition_id: "senses_blurred",
    condition_category: "Senses",
    condition_label: "Blurred Vision",
    field_type: "boolean",
  },
  {
    condition_id: "senses_impairment",
    condition_category: "Senses",
    condition_label: "Visual Impairment",
    field_type: "boolean",
  },
  {
    condition_id: "senses_tinnitus",
    condition_category: "Senses",
    condition_label: "Tinnitus",
    field_type: "boolean",
  },
  {
    condition_id: "senses_loss",
    condition_category: "Senses",
    condition_label: "Hearing Loss",
    field_type: "boolean",
  },
  {
    condition_id: "senses_speech",
    condition_category: "Senses",
    condition_label: "Speech Impairment",
    field_type: "boolean",
  },
  {
    condition_id: "general_fatigue",
    condition_category: "General",
    condition_label: "Fatigue",
    field_type: "boolean",
  },
  {
    condition_id: "general_tension",
    condition_category: "General",
    condition_label: "Tension",
    field_type: "boolean",
  },
  {
    condition_id: "general_fog",
    condition_category: "General",
    condition_label: "Fog",
    field_type: "boolean",
  },
  {
    condition_id: "general_stress",
    condition_category: "General",
    condition_label: "Stress",
    field_type: "boolean",
  },
  {
    condition_id: "general_irritability",
    condition_category: "General",
    condition_label: "Irritability",
    field_type: "boolean",
  },
  {
    condition_id: "general_nervousness",
    condition_category: "General",
    condition_label: "Nervousness",
    field_type: "boolean",
  },
  {
    condition_id: "general_sleep",
    condition_category: "General",
    condition_label: "Sleep",
    field_type: "boolean",
  },
  {
    condition_id: "general_fevers",
    condition_category: "General",
    condition_label: "Fevers",
    field_type: "boolean",
  },
  {
    condition_id: "general_sweats",
    condition_category: "General",
    condition_label: "Sweats",
    field_type: "boolean",
  },
  {
    condition_id: "general_smell",
    condition_category: "General",
    condition_label: "Smell",
    field_type: "boolean",
  },
  {
    condition_id: "general_taste",
    condition_category: "General",
    condition_label: "Taste",
    field_type: "boolean",
  },
  {
    condition_id: "other_menstruation",
    condition_category: "Other",
    condition_label: "Difficult Menstruation",
    field_type: "boolean",
  },
  {
    condition_id: "other_breastfeeding",
    condition_category: "Other",
    condition_label: "Breastfeeding",
    field_type: "boolean",
  },
  {
    condition_id: "other_menopause",
    condition_category: "Other",
    condition_label: "Menopause",
    field_type: "boolean",
  },
  {
    condition_id: "other_premenstrual",
    condition_category: "Other",
    condition_label: "Premenstrual",
    field_type: "boolean",
  },
  {
    condition_id: "other_intercourse",
    condition_category: "Other",
    condition_label: "Painful Intercourse",
    field_type: "boolean",
  },
  {
    condition_id: "other_fertility",
    condition_category: "Other",
    condition_label: "Fertility Problems",
    field_type: "boolean",
  },
  {
    condition_id: "other_libido",
    condition_category: "Other",
    condition_label: "Low / Loss of Libido",
    field_type: "boolean",
  },
  {
    condition_id: "other_prostate",
    condition_category: "Other",
    condition_label: "Prostate Problems",
    field_type: "boolean",
  },
  {
    condition_id: "other_testicular",
    condition_category: "Other",
    condition_label: "Testicular Pain",
    field_type: "boolean",
  },
  {
    condition_id: "other_erectile",
    condition_category: "Other",
    condition_label: "Erectile Dysfunction",
    field_type: "boolean",
  },
  {
    condition_id: "other_diabetes",
    condition_category: "Other",
    condition_label: "Diabetes",
    field_type: "boolean",
  },
  {
    condition_id: "other_cancer",
    condition_category: "Other",
    condition_label: "Cancer",
    field_type: "boolean",
  },
  {
    condition_id: "other_hepatitis",
    condition_category: "Other",
    condition_label: "Hepatitis B/C",
    field_type: "boolean",
  },
  {
    condition_id: "other_hiv",
    condition_category: "Other",
    condition_label: "HIV / AIDS",
    field_type: "boolean",
  },
  {
    condition_id: "other_adhd",
    condition_category: "Other",
    condition_label: "ADHD / Autism / Aspergers",
    field_type: "boolean",
  },
];

export { preAssessmentTwoSchema };
