import { useContext, useEffect, useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { useHistory, Link } from "react-router-dom";

import { UserContext } from "../../context/userContext";
import Layout from "../../components/Layout";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { Mixpanel } from "../../utils/Mixpanel";
import * as Sentry from "@sentry/react";

const Register = () => {
  const history = useHistory();
  const user = useContext(UserContext);

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const [loggingIn, setIsLoggingIng] = useState(false);
  const [error, setError] = useState();
  const [address, setAddress] = useState({});

  const loadWidget = async () => {
    let script = document.createElement("script");

    script.src = "https://api.addressfinder.io/assets/v3/widget.js";
    script.async = true;
    await document.body.appendChild(script);

    script.onload = async () => {
      try {
        let widget = await new window.AddressFinder.Widget(
          document.getElementById("addrs_1"),

          process.env.REACT_APP_ADDRESS_FINDER_KEY,
          "AU",
          {
            address_params: {},
          }
        );
        widget.on("result:select", (fullAddress, metaData) => {
          setAddress({
            lineOne: metaData.address_line_1,
            lineTwo: metaData.address_line_2,
            suburb: metaData.locality_name,
            state: metaData.state_territory,
            postCode: metaData.postcode,
          });
          document.getElementById("password").focus();
        });
      } catch (error) {
        Sentry.captureException("Error loading Address Finder...");
        alert(
          "We can't proceed with an online booking right now - please call us on 1300 859 785 to book an appointment"
        );
        Mixpanel.track("Register | Failure | Address Finder");

        history.push("/");
      }
    };
  };

  const createUser = async (data) => {
    setIsLoggingIng(true);
    setError();

    try {
      if (data.password !== data.password2) {
        throw new Error("Passwords dont match");
      }

      if (!address.state) {
        throw new Error("Please ensure your address is correct");
      }

      const splitDob = data.dob.split("-");

      const payload = {
        client: {
          first_name: data.firstName,
          email: data.email,
        },
        profile: {
          last_name: data.lastName,
          phone: data.phone,
          dob_day: splitDob[2],
          dob_month: splitDob[1],
          dob_year: splitDob[0],
          street_address: data.addressLine1,
          client_type_id: data.clientType,
          password: data.password,
          password_confirm: data.password2,
          suburb: data.suburb,
          state: data.state,
          postcode: data.postCode,
          date_of_birth: data.dob,
          gender: Number(data.gender),
        },
      };

      const res = await axios({
        method: "POST",
        url: process.env.REACT_APP_API_URL + "/client/new",
        data: payload,
        headers: {
          "Content-Type": "application/json",
        },
      });

      await user.setUserDetails(res.data);
    } catch (error) {
      setIsLoggingIng(false);
      if (error.response) {
        if (error.response.status === 403) {
          await setError("Are you already signed up?");
        } else {
          Mixpanel.track("Register | Failure");
          Sentry.captureException(error);
          await setError(error.response.data.error);
        }
      } else {
        await setError(error.message);
      }

      document.getElementById("register").scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    if (user.userDetails.id) {
      Mixpanel.track("Register | Success");
      history.push("/onboarding");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.userDetails]);

  useEffect(() => {
    loadWidget();
    Mixpanel.track("View | Register");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user.userDetails.id) {
      history.push("/onboarding");
      return;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.userDetails]);

  return (
    <Layout>
      <div
        className="preAssessmentm-auto py-8 px-0 md:px-4 w-full md:w-3/4 lg:w-3/4 xl:w-2/3 h-auto my-8"
        id="register"
      >
        <form
          onSubmit={handleSubmit(createUser)}
          className="mb-4 bg-offwhite p-4 md:p-8 rounded"
          id="register-form"
        >
          <div className="mb-8 text-center">
            <p className="text-3xl font-bold mb-2">Register</p>
            <p className="text-lg">Please complete all fields</p>
          </div>
          {error ? (
            <div
              className="flex items-center bg-joy text-offwhite text-sm font-bold px-4 py-3 w-full mb-8 mt-8 rounded"
              role="alert"
              id="error"
            >
              <svg
                className="fill-current w-4 h-4 mr-2"
                xmlns="https://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M12.432 0c1.34 0 2.01.912 2.01 1.957 0 1.305-1.164 2.512-2.679 2.512-1.269 0-2.009-.75-1.974-1.99C9.789 1.436 10.67 0 12.432 0zM8.309 20c-1.058 0-1.833-.652-1.093-3.524l1.214-5.092c.211-.814.246-1.141 0-1.141-.317 0-1.689.562-2.502 1.117l-.528-.88c2.572-2.186 5.531-3.467 6.801-3.467 1.057 0 1.233 1.273.705 3.23l-1.391 5.352c-.246.945-.141 1.271.106 1.271.317 0 1.357-.392 2.379-1.207l.6.814C12.098 19.02 9.365 20 8.309 20z" />
              </svg>
              <div className="flex justify-center flex-col w-full">
                <p className="mb-2">{error}</p>
                <p>
                  Do you need to{" "}
                  <Link to="/login" className="underline" id="register-login">
                    login
                  </Link>
                  ?
                </p>
              </div>
            </div>
          ) : null}
          <div className="grid grid-cols-1 md:grid-cols-2 md:gap-2">
            <div className="mb-4">
              <label className="form-label">First Name*</label>
              <input
                name="firstName"
                ref={register({ required: true })}
                className="text-box"
                placeholder="Enter your first name"
                autoCapitalize="none"
                autoFocus="autofocus"
              ></input>
              {errors.firstName ? (
                <span className="form-field-error">
                  {errors.firstName && "First name is required"}
                </span>
              ) : null}
            </div>
            <div className="mb-4">
              <label className="form-label">Last Name*</label>
              <input
                name="lastName"
                ref={register({ required: true })}
                className="text-box"
                placeholder="Enter your last name"
                autoCapitalize="none"
              ></input>
              {errors.lastName ? (
                <span className="form-field-error">Last name is required</span>
              ) : null}
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 md:gap-2">
            <div className="mb-4">
              <label className="form-label">What best describes you?*</label>
              <select
                name="clientType"
                ref={register({ required: true, minLength: 1 })}
                className="text-box"
              >
                <option value="1" defaultValue>
                  Adult
                </option>
                <option value="2">Concession</option>
              </select>
            </div>
            <div className="mb-4">
              <label className="form-label">What is your gender?*</label>
              <select
                name="gender"
                ref={register({ required: true, minLength: 1 })}
                className="text-box"
              >
                <option value="2" defaultValue>
                  Female
                </option>
                <option value="1">Male</option>
                <option value="0">Other</option>
              </select>
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 md:gap-2">
            <div className="mb-4">
              <label className="form-label">Date of Birth*</label>
              <input
                name="dob"
                type="date"
                ref={register({ required: true })}
                className="text-box"
                autoCapitalize="none"
              ></input>
              {errors.dob ? (
                <span className="form-field-error">
                  Date of birth is required
                </span>
              ) : null}
            </div>
            <div className="mb-4">
              <label className="form-label">Phone Number*</label>
              <input
                name="phone"
                ref={register({ required: true })}
                className="text-box"
                placeholder="Enter your phone number"
                autoCapitalize="none"
                type="tel"
              ></input>
              {errors.phone ? (
                <span className="form-field-error">
                  Telephone number is required
                </span>
              ) : null}
            </div>
          </div>
          <div className="mb-4">
            <label className="form-label">Email*</label>
            <input
              name="email"
              ref={register({ required: true })}
              className="text-box"
              placeholder="Enter your email address"
              autoCapitalize="none"
              type="email"
            ></input>
            {errors.email ? (
              <span className="form-field-error">Email is required</span>
            ) : null}
          </div>
          <div className="my-4">
            <div className="mb-4">
              <label className="form-label">Address*</label>
              <input
                name="addressLine1"
                id="addrs_1"
                type="text"
                ref={register({ required: true })}
                className="text-box"
                placeholder="Start typing your address"
                autoCapitalize="none"
                value={address.lineOne}
              ></input>
              {errors.addressLine1 ? (
                <span className="form-field-error">Address is required</span>
              ) : null}
            </div>
            {address.suburb ? (
              <span>
                <div className="mb-4">
                  <label className="form-label">Address Line 2</label>
                  <input
                    name="addressLine2"
                    ref={register({ required: false })}
                    className="text-box"
                    placeholder=""
                    readOnly={true}
                    autoCapitalize="none"
                    value={address.lineTwo}
                  ></input>
                </div>
                <div className="mb-4">
                  <label className="form-label">Suburb</label>
                  <input
                    name="suburb"
                    ref={register({ required: true })}
                    className="text-box"
                    placeholder=""
                    readOnly={true}
                    autoCapitalize="none"
                    value={address.suburb}
                  ></input>
                </div>
                <div className="grid grid-cols-2 gap-2">
                  <div className="mb-4">
                    <label className="form-label">State</label>
                    <input
                      name="state"
                      ref={register({ required: true })}
                      className="text-box"
                      placeholder=""
                      readOnly={true}
                      autoCapitalize="none"
                      value={address.state}
                    ></input>
                  </div>
                  <div className="mb-4">
                    <label className="form-label">Post Code</label>
                    <input
                      name="postCode"
                      ref={register({ required: true, minLength: 4 })}
                      className="text-box"
                      placeholder=""
                      readOnly={true}
                      autoCapitalize="none"
                      value={address.postCode}
                    ></input>
                  </div>
                </div>
              </span>
            ) : null}
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 md:gap-2 my-4">
            <div className="mb-4">
              <label className="form-label">Password (8+ characters)*</label>

              <input
                name="password"
                id="password"
                ref={register({ required: true, minLength: 8 })}
                className="text-box"
                placeholder="Enter a password"
                type="password"
                autoCapitalize="none"
              ></input>
              {errors.password ? (
                <span className="form-field-error">
                  A valid password is required
                </span>
              ) : null}
            </div>
            <div className="mb-4">
              <label className="form-label">Confirm Password*</label>
              <input
                name="password2"
                ref={register({ required: true, minLength: 8 })}
                className="text-box"
                placeholder="Enter password again"
                type="password"
                autoCapitalize="none"
              ></input>
              {errors.password2 ? (
                <span className="form-field-error">
                  Password confirmation is required
                </span>
              ) : null}
            </div>
          </div>
          <div className="mb-4 mt-4">
            <button type="submit" className="btn-joy" id="create-account">
              {loggingIn ? (
                <FontAwesomeIcon icon={faCircleNotch} spin />
              ) : (
                "Create Account"
              )}
            </button>
          </div>
        </form>
      </div>
    </Layout>
  );
};

export default Register;
