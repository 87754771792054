import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import Layout from "../../components/Layout";
import ClipLoader from "react-spinners/ClipLoader";

import queryString from "query-string";
import axios from "axios";
import { Mixpanel } from "../../utils/Mixpanel";
import Confetti from "react-confetti";
import { useWindowSize } from "react-use";

const Payment = () => {
  const { search } = useLocation();
  const { width, height } = useWindowSize();
  const [bookingId, setBookingId] = useState(0);
  const [paymentRequired, setPaymentRequired] = useState(false);
  const [bookingAmount, setBookingAmount] = useState(0);
  const [bookingEmail, setBookingEmail] = useState("");
  const [loading, setLoading] = useState(true);
  const [bookingExpired, setBookingExpired] = useState(false);

  useEffect(() => {
    const getBookingId = () => {
      Mixpanel.track("Payment | Get Booking Id");
      const values = queryString.parse(search);
      if (!values.booking_id) {
        Mixpanel.track("Payment | No Booking Id");
        return <h1>No booking ID found</h1>;
      }
      setBookingId(values.booking_id);
      Mixpanel.track("Payment | Got Booking Id");
    };
    getBookingId();
  }, [search]);

  useEffect(() => {
    const getBookingValue = async () => {
      try {
        Mixpanel.track("Payment | Get Booking Value");
        const res = await axios.get(
          `${process.env.REACT_APP_API_URL}/booking/query_booking?booking_id=${bookingId}`
        );

        if (res.data.status && res.data.status === 404) {
          setBookingExpired(true);
          return;
        }

        if (
          res.data.value > 0 &&
          res.data.value < 100 && //TODO: This is here just incase for some reason the value of the booking comes back in cents rather than dollars  - just being cautious :) If you need to take booking values of > $100 then youll need to change this value
          res.data.paid === 0 &&
          res.data.telehealth === 1
        ) {
          setBookingAmount(res.data.value * 100);
          setBookingEmail(res.data.email);
          setPaymentRequired(true);

          Mixpanel.track("Payment | Payment Required");
        } else {
          Mixpanel.track("Payment | No Payment Required");
        }
      } catch (error) {
        if (error.response.status === 404) {
          setBookingExpired(true);
          return;
        }
        console.log(error);
        window.alert("Something went wrong :(");
      } finally {
        setLoading(false);
      }
    };
    if (!bookingId) {
      return;
    }
    setTimeout(() => {
      getBookingValue();
    }, 3000);
  }, [bookingId]);

  useEffect(() => {
    const createPaymentButton = async () => {
      const script = document.createElement("script");
      script.src = "https://secure.ewaypayments.com/scripts/eCrypt.js";
      script.class = "eway-paynow-button";
      script.setAttribute("class", "eway-paynow-button");
      script.setAttribute(
        "data-publicapikey",
        "epk-EAC76755-35B1-43E6-978D-75BCFD7B408A"
      );
      script.setAttribute("data-amount", bookingAmount);
      script.setAttribute("data-currency", "AUD");
      script.setAttribute("data-buttoncolor", "rgba(255, 88, 93)");
      script.setAttribute("data-label", "Pay Now");
      script.setAttribute("data-email", bookingEmail);
      script.setAttribute("data-invoiceref", bookingId);
      const resultUrl = `${process.env.REACT_APP_APP_URL}/#/payment-complete?booking_id=${bookingId}`;
      script.setAttribute("data-resulturl", resultUrl);
      const paymentElement = document.getElementById("eway-container");
      await paymentElement.appendChild(script);
      Mixpanel.track("Payment | Create Payment Button");
    };
    if (bookingAmount && paymentRequired && bookingEmail && bookingId) {
      createPaymentButton();
    }
  }, [bookingAmount, paymentRequired, bookingEmail, bookingId]);

  return (
    <Layout>
      <div className="o py-8 px-0 md:px-4 w-full md:w-3/4 lg:w-3/4 xl:w-1/2 h-auto my-8">
        <div className="mb-4 bg-offwhite p-4 md:p-8 rounded">
          {paymentRequired ? (
            <>
              <div className="text-center mb-4">
                <h1 className="text-2xl font-semibold mb-4">
                  Please make a payment for ${bookingAmount / 100}
                </h1>
                <h2 className="text-left text-xl mb-8">
                  Your appointment with Endeavour Clinic is not fully confirmed
                  until payment is received.
                </h2>
              </div>
              <div className="flex justify-center">
                <div id="eway-container"></div>
              </div>
            </>
          ) : (
            <div className="text-center mb-4 flex flex-col items-center">
              {loading ? (
                <>
                  <h1 className="text-2xl font-semibold mb-4">
                    Checking appointment details...
                  </h1>

                  <ClipLoader size={48} color={"black"} loading={true} />
                </>
              ) : bookingExpired ? (
                <>
                  <h1 className="text-2xl font-semibold mb-2">
                    Booking expired
                  </h1>
                  <h2 className="text-xl">Please make a new booking.</h2>
                </>
              ) : (
                <>
                  <h1 className="text-2xl font-semibold">
                    No payment required
                  </h1>
                  <Confetti width={width} height={height} />
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default Payment;
