import React, { createContext, useState } from "react";

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [id, setId] = useState(0);
  const [firstName, setFirstName] = useState("");
  const [email, setEmail] = useState("");
  const [location, setLocation] = useState(0);

  const [userDetails, setUserDetails] = useState({});
  const [preassessment, setPreassessment] = useState({});
  const [bookingDetails, setBookingDetails] = useState({});

  return (
    <UserContext.Provider
      value={{
        id,
        firstName,
        email,
        location,
        userDetails,
        preassessment,
        bookingDetails,
        setId,
        setFirstName,
        setEmail,
        setLocation,
        setUserDetails,
        setPreassessment,
        setBookingDetails,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
