import React, { useEffect, useState } from "react";
// import axios from "axios";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";

import Layout from "../../components/Layout";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";

import { Mixpanel } from "../../utils/Mixpanel";

import axios from "axios";

const ForgotPassword = () => {
  const { register, handleSubmit } = useForm();

  const [resetting, setResetting] = useState(false);
  const [resetSent, setResetSent] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    Mixpanel.track("View | Password Reset");
  }, []);

  const sendPasswordReset = async (data) => {
    await setResetting(true);

    const formData = new FormData();
    formData.append("email", data.email);
    formData.append("utf8", "%E2%9C%93");
    formData.append("authenticity_token", Math.random().toString(36));

    try {
      const res = await axios({
        method: "POST",
        url: process.env.REACT_APP_API_URL + "/request_password_reset",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data;",
        },
      });

      if (res.status !== 200) {
        throw new Error("Invalid details");
      }

      setResetting(false);
      setResetSent(true);
      Mixpanel.track("Forgot Password | Request");
    } catch (error) {
      console.log(error);
      setResetting(false);
      setError(true);
      Mixpanel.track("Forgot Password | Failure");
    }
  };

  return (
    <Layout>
      <div className="preAssessmentm-auto py-8 px-0 md:px-4 w-full md:w-3/4 lg:w-3/4 xl:w-2/3 h-auto my-8">
        <form
          onSubmit={handleSubmit(sendPasswordReset)}
          className="mb-4 bg-offwhite p-8 rounded"
        >
          <div className="mb-8 text-center">
            <p className="text-3xl font-bold">Get a new password</p>
          </div>
          {error ? (
            <div
              className="flex items-center bg-joy text-white text-sm font-bold px-4 py-3 w-full mb-8 mt-8 rounded"
              role="alert"
            >
              <p className="text-left">
                Nothing found for that email address. <br />
                <br />
                Please{" "}
                <Link to="/register" className="underline">
                  register
                </Link>{" "}
                or try again.
                <br />
                <br />
                If you're having issues please call us on 1300 859 785
              </p>
            </div>
          ) : null}
          {resetSent ? (
            <div
              className="flex items-center text-black text-base px-4 py-3 w-full mb-8 mt-8 rounded"
              role="alert"
            >
              <p className="text-left">
                If that email address is registered in our system then we've
                emailed you a link to get a new password.
                <br />
                <br />
                Please check your email in a couple of minutes, sometimes these
                emails go to Junk mail.
                <br />
                <br />
                If you're having issues please call us on 1300 859 785
              </p>
            </div>
          ) : (
            <>
              <div className="mb-4">
                <label className="form-label">
                  Please enter your email address
                </label>
                <input
                  name="email"
                  ref={register({ required: true })}
                  className="text-box"
                  placeholder="email address"
                  autoCapitalize="none"
                  autoFocus="autofocus"
                ></input>
              </div>

              <div className="mb-4">
                <button type="submit" className="btn-joy">
                  {resetting ? (
                    <FontAwesomeIcon icon={faCircleNotch} spin />
                  ) : (
                    "Continue"
                  )}
                </button>
              </div>
            </>
          )}
        </form>
      </div>
    </Layout>
  );
};

export default ForgotPassword;
