import { useContext, useEffect } from "react";
import { Link } from "react-router-dom";

import { UserContext } from "../../context/userContext";
import Layout from "../../components/Layout";

import { Mixpanel } from "../../utils/Mixpanel";

const Onboarding = () => {
  const user = useContext(UserContext);

  useEffect(() => {
    Mixpanel.track("View | Landing");
  }, []);

  return (
    <Layout>
      <div className="mb-8 text-center">
        <h1 className="text-5xl text-offwhite font-semibold mb-2">
          Hi {user.userDetails.first_name}
        </h1>
        <p className="text-2xl text-offwhite font-semibold">
          What would you like to do today?
        </p>
      </div>
      <div className="grid grid-cols-2 gap-2 h-auto">
        <Link to="/booking" className="flex justify-center">
          <button
            className="btn-joy md:text-lg text-xl h-full"
            id="landing-new-client"
          >
            {" "}
            Make A Booking
          </button>
        </Link>
        <Link
          to="/client-registration/personal-details"
          className="flex justify-center"
        >
          <button
            className="btn-trans-white text-lg md:text-xl h-full"
            id="landing-existing-client"
          >
            {" "}
            Client Registration Form
          </button>
        </Link>
      </div>
    </Layout>
  );
};

export default Onboarding;
