import { Switch, Route, HashRouter as Router } from "react-router-dom";

import {
  Landing,
  Register,
  Login,
  BookingDetails,
  BookingComplete,
  ForgotPassword,
  ResetPassword,
  Onboarding,
  Payment,
  PaymentComplete,
} from "./pages/index";

import {
  PersonalDetails,
  PreAssessmentOne,
  PreAssessmentTwo,
  PreAssessmentThree,
  FormComplete,
} from "./pages/ClientRegistrationForm";

//TODO: Create Privateroute for logged in users

const App = () => {
  return (
    <Router>
      <Switch>
        <Route path="/register">
          <Register />
        </Route>
        <Route path="/login">
          <Login />
        </Route>
        <Route path="/booking">
          <BookingDetails />
        </Route>
        <Route path="/complete">
          <BookingComplete />
        </Route>
        <Route path="/forgot">
          <ForgotPassword />
        </Route>
        <Route path="/reset-password">
          <ResetPassword />
        </Route>
        <Route path="/onboarding">
          <Onboarding />
        </Route>
        <Route path="/client-registration/personal-details">
          <PersonalDetails />
        </Route>
        <Route path="/client-registration/preassessment/1">
          <PreAssessmentOne />
        </Route>
        <Route path="/client-registration/preassessment/2">
          <PreAssessmentTwo />
        </Route>
        <Route path="/client-registration/preassessment/3">
          <PreAssessmentThree />
        </Route>
        <Route path="/client-registration/complete">
          <FormComplete />
        </Route>
        <Route path="/payment">
          <Payment />
        </Route>
        <Route path="/payment-complete">
          <PaymentComplete />
        </Route>
        <Route path="/">
          <Landing />
        </Route>
      </Switch>
    </Router>
  );
};

export default App;
