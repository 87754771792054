import { memo, useContext } from "react";
import { Link } from "react-router-dom";
import { UserContext } from "../../context/userContext";

const Layout = ({ children }) => {
  const user = useContext(UserContext);
  return (
    <div
      className="w-full min-h-screen h-full bg-offwhite p-4 md:p-16 flex justify-center items-center flex-col bg-fixed bg-no-repeat bg-cover"
      style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), #000), url(https://www.endeavourclinic.com.au/wp-content/uploads/2020/09/glowing_skin_2000x1333.jpg)`,
      }}
    >
      <div className="flex justify-center object-cover w-full pb-4">
        <Link to={user.userDetails.id ? "/onboarding" : "/"}>
          <img src="/images/logo.png" alt="endeavour-logo" />
        </Link>
      </div>
      <>{children}</>
    </div>
  );
};

export default memo(Layout);
